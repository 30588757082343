import React, { useEffect, useState, memo, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import './UserAccessForm.css';
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux';
import WvLanguage from '../Multilingual/WvLanguage';
import { Chip } from 'primereact/chip';
function UserAccessForm(props) {
  const [multiSelectOptions, setMultiSelectOptions] = useState([]);
  const stateData = useSelector((state) => state[props.reducerName]);
  const multiselectRef = useRef(null);
  const { t } = WvLanguage();
  const setMultiSelectData = (e) => {
    props.onChangeHandler(e.target.value, 'region');
  };
  const setOptions = () => {
    let rows = props.data.find((obj) => obj.region === stateData['region']);
    setMultiSelectOptions(rows['useroffice']);
  };
  function optionsTemplate(option) {
    return (
      <div className="options">
        <div>{option.name}</div>
      </div>
    );
  }
  function selectedOptionsTemplate(option) {
    if (option) {
      return (
        <div
          className="options-selected"
          style={{ width: 'fit-content', float: 'left', margin: '5px' }}
        >
          <Chip label={option.name} />
        </div>
      );
    }

    return `${t('select_placeholder')}`;
  }
  useEffect(() => {
    multiselectRef.current.style = 'display:none';
  }, [multiSelectOptions]);
  const sumbitHandler = () => {
    if (stateData['office'].length === 0) {
      multiselectRef.current.parentElement.children[0].classList.add('p-focus');
      multiselectRef.current.style = 'display:block';
    } else {
      multiselectRef.current.style = 'display:none';
      props.onChangeHandler(true, 'showGrid');
    }
  };
  const multiSelectHandler = (e) => {
    if (e.value.length === 0) {
      multiselectRef.current.style = 'display:block';
    } else {
      multiselectRef.current.style = 'display:none';
    }
    props.onChangeHandler(e.value, 'office');
  };
  const clearHandler = () => {
    multiselectRef.current.style = 'display:none';
    props.clearHandler();
  };
  return (
    <>
      <div className="p-grid user-access-form p-align-center">
        <div className="p-col-12 p-md-6 p-lg-5 p-pt-1 p-pl-1 p-pb-1">
          <p className="userOffice-lbl">
            {t('region')}
            <span className="mand-field">*</span>
          </p>
          <Dropdown
            options={props.data.map((opt) => opt.region)}
            placeholder={t('select_placeholder')}
            name="operation"
            onChange={(e) => setMultiSelectData(e)}
            value={stateData['region']}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-5 p-pt-1 p-pl-1 p-pb-1">
          <p className="userOffice-lbl">
            {t('user_office')}
            <span className="mand-field">*</span>
          </p>
          <div style={{ position: 'relative' }}>
            <MultiSelect
              value={stateData['office']}
              options={multiSelectOptions}
              name={props.id}
              onChange={(e) => multiSelectHandler(e)}
              optionLabel="name"
              placeholder={t('select_placeholder')}
              filter
              className="multiselect-custom"
              itemTemplate={optionsTemplate}
              selectedItemTemplate={selectedOptionsTemplate}
              maxSelectedLabels={3}
              disabled={stateData['region'].length == 0 ? true : false}
              onFocus={() => setOptions()}
            />
            <p ref={multiselectRef} className="mand-field-msg line-break">
              <span className="pi pi-exclamation-triangle p-mr-1"></span>
              {t('required_field')}
            </p>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-2 form-mobile-mr">
          <Button
            label={t('reset_button')}
            className="p-button-outlined p-button-secondary wv-cancel-btn p-mr-2"
            disabled={stateData['region'].length == 0 ? true : false}
            onClick={() => clearHandler()}
          />
          <Button
            label={t('search_button')}
            className="p-button-warning"
            disabled={stateData['region'].length == 0 ? true : false}
            onClick={() => sumbitHandler()}
          />
        </div>
      </div>
    </>
  );
}
export default memo(UserAccessForm);
