import axios from 'axios';
const JWTInterceptor = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 6 * 60 * 1000,
});

const requestHandler = (request) => {
  request.meta = request.meta || {};
  request.meta.requestStartedAt = new Date().getTime();
  // let token = `${process.env.REACT_APP_API_Client_ID}:${process.env.REACT_APP_API_Client_SECRET}`;
  // token = window.btoa(token);
  let query = new URLSearchParams(window.location.search);
  let allowMethods = ['post', 'put', 'delete', 'get'];
  if (allowMethods.includes(request.method.toLowerCase())) {
    let token = localStorage.getItem('oneLoginDetails') || '';
    try {
      if (query.get('type')) {
        if (localStorage.getItem('pcrm_token')) {
          token = JSON.parse(localStorage.getItem('pcrm_token'));
          token = token.Token;
        } else {
          token = query.get('token');
        }
      } else {
        token = JSON.parse(token) || {};
        token = token.access_token;
      }
    } catch (err) {
      token = '';
    }
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = '/login';
  }
  return response;
};

const errorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    let query = new URLSearchParams(window.location.search);
    if (query.get('type')) {
      window.location.replace('/unauthorized?type=nonav');
    } else {
      window.location.replace('/unauthorized');
    }
  }
  return Promise.reject(error);
};

JWTInterceptor.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
JWTInterceptor.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
export default JWTInterceptor;
