import axios from 'axios';
const JWTPcrmInterceptor = axios.create({
  baseURL: `${process.env.REACT_APP_PCRM_API_BASE_URL}`,
  timeout: 4 * 60 * 1000,
});
const requestHandler = (request) => {
  request.meta = request.meta || {};
  request.meta.requestStartedAt = new Date().getTime();
  // let token = `${process.env.REACT_APP_API_Client_ID}:${process.env.REACT_APP_API_Client_SECRET}`;
  // token = window.btoa(token);
  // request.headers.Authorization = `Basic ${token}`;
  let allowMethods = ['post', 'put', 'delete', 'get'];
  if (allowMethods.includes(request.method.toLowerCase())) {
    let token = '';
    let query = new URLSearchParams(window.location.search);
    try {
      if (query.get('type')) {
        if (localStorage.getItem('pcrm_token')) {
          token = JSON.parse(localStorage.getItem('pcrm_token'));
          token = token.Token;
        } else {
          token = query.get('token');
        }
      } else {
        token = localStorage.getItem('oneLoginDetails') || {};
        token = JSON.parse(token) || {};
        token = token.access_token;
      }
    } catch (err) {
      token = '';
    }
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = '/login';
  }

  return response;
};

const errorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.replace('/unauthorized?type=nonav');
  }
  return Promise.reject(error);
};

JWTPcrmInterceptor.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
JWTPcrmInterceptor.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
export default JWTPcrmInterceptor;
