import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './App.css';
import AppRouting from './Routing/AppRoutings';
import IdleTimeComponent from './Odic/IdleTimeComponent';
import WvLanguage from './component/Common/Multilingual/WvLanguage';
import FDROService from './component/Module/FundRaisingOfficeSetting/Utils/FDROService.js';
import { useSelector, useDispatch } from 'react-redux';
import { ThreeDots } from 'react-loader-spinner';
import { BlockUI } from 'primereact/blockui';
import LandingScreen from './component/Module/SSO/UI/LandingPage';
import PrcdsSkeleton from './component/Module/SSO/UI/Skeleton';
import { ProfileService } from './component/Module/SSO/utils/Service';
import jwtDecode from 'jwt-decode';
import OidcService from './Odic/utils/service';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import OidcAuthComponent from './Odic/OidcAuthComponent';
import LegalConsent from './component/Module/SSO/UI/legalConsent';
import AccessDeniedPage from './Odic/AccessDeniedPage';
import UnauthorizedScreen from './Odic/UnauthorizedPage';
const App = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { t, changeLanguage } = WvLanguage();
  const [ApiCallDone, SetApiCalldone] = useState(false);
  const [ProfileCheckDone, SetProfileCheckDone] = useState(false);
  const reduxData = useSelector((state) => state.fdroSettingReducer);
  const userReduxData = useSelector((state) => state.LoginReducer);
  const [ShowSpinner, SetShowSpinner] = useState(false);
  const action = useDispatch();
  const [Message, SetMessage] = useState({
    open: false,
    title: '',
    body: '',
  });
  useEffect(() => {
    if (location.pathname !== '/auth' && location.pathname !== '/') {
      localStorage.setItem('userUrl', JSON.stringify(location));
    }
    if (query.get('type')) {
      action({
        type: 'Common_Others',
        payload: {
          name: 'userLoggedIn',
          value: true,
        },
      });
      let data = JSON.stringify({
        Token: query.get('token'),
        RefreshToken: query.get('rtoken'),
      });
      localStorage.setItem('pcrm_token', data);
      return false;
    }
    if (!localStorage.getItem('access_token') && localStorage.getItem('oneLoginDetails') === null) {
      if (location.pathname === '/auth') {
        if (query.get('error') === 'access_denied') {
          history.push('/accessDeniedError');
        } else {
          history.push({ pathname: '/auth', search: location.search });
        }
      } else if (location.pathname === '/unauthorized') {
        history.push('/unauthorized');
      } else {
        history.push('/auth');
      }
    } else {
      let userEmail = sessionStorage.getItem('UserLogged');
      userEmail = userEmail || '';
      if (userEmail.length === 0) {
        localStorage.clear();
        history.push({
          pathname: '/auth',
          state: { redirect: true, pathname: location.pathname, search: location.search },
        });
      }
    }
  }, []);
  useEffect(() => {
    if (userReduxData.userLoggedIn) {
      checkProfile();
    }
  }, [userReduxData.userLoggedIn]);
  useEffect(() => {
    let userEmail = sessionStorage.getItem('UserLogged');
    userEmail = userEmail || '';
    if (userReduxData.userLoggedIn === false && userEmail.length !== 0) {
      checkProfile();
    }
  }, []);
  const checkProfile = () => {
    let userInfo = {};
    let isPcrm = query.get('type');
    if (window.location.pathname === '/unauthorized') return false;
    // if (isPcrm && isPcrm === 'nonav') {
    //   if (query.get('email')) {
    //     userInfo.email = query.get('email');
    //   } else {
    //     SetMessage({
    //       open: true,
    //       title: t('user_profile'),
    //       body: t('pcrm_no_email_found'),
    //     });
    //     return false;
    //   }
    // } else {
    //   userInfo = localStorage.getItem('profile');
    //   userInfo = JSON.parse(userInfo) || {};
    // }
    // if (Object.keys(userInfo).length !== 0) {
    SetShowSpinner(true);
    ProfileService.getProfileDetail()
      .then((res) => {
        if (Object.values(res.data.User).length === 0) {
          if (isPcrm && isPcrm === 'nonav') {
            SetShowSpinner(false);
            SetMessage({
              open: true,
              title: t('user_profile'),
              body: t('no_user_profile'),
            });
          } else {
            createProfile();
          }
        } else {
          if (res.data.User.Status === 'Inactive') {
            SetMessage({
              open: true,
              title: t('user_profile'),
              body: t('inactive_user_profile'),
            });
          } else {
            action({
              type: 'Update_User_Detail',
              payload: {
                value: res.data.User,
              },
            });
            getRole(res.data.User.UserId);
          }
        }
      })
      .catch((err) => {
        SetShowSpinner(false);
        SetMessage({
          open: true,
          title: t('user_profile'),
          body: t('unable_to_fetch_profile'),
        });
        console.log('unable to fetch user profile');
      });
    // }
  };
  const createProfile = () => {
    let userInfo = localStorage.getItem('profile');
    userInfo = JSON.parse(userInfo);
    let requestBody = {
      User: {
        FirstName: userInfo.params ? userInfo.params.Firstname : '',
        LastName: userInfo.params ? userInfo.params.Lastname : '',
        FDRO: process.env.REACT_APP_FDRO_CODE,
        Region: process.env.REACT_APP_REGION_CODE,
        Email: userInfo.email,
        // UserType: 'Application User',
      },
    };
    ProfileService.createProfile(requestBody)
      .then((res) => {
        SetShowSpinner(false);
        SetMessage({
          open: true,
          title: t('user_profile'),
          body: t('norole_assigned_success'),
        });
      })
      .catch((err) => {
        if (err.response.data.message === 'User details already exists') {
          getRole();
        } else {
          SetShowSpinner(false);
          SetMessage({
            open: true,
            title: t('user_profile'),
            body: t('norole_assigned_error'),
          });
        }
      });
  };
  const getRole = (id) => {
    ProfileService.getRoles(id)
      .then((res) => {
        let result = res.data.UserAccess;
        if (result.length === 0) throw new Error('Role not created');
        let roleArray = [];
        for (let module = 0; module < result.length; module++) {
          let roleObj = {};
          roleObj['ModuleId'] = result[module].ModuleId;
          roleObj['ModuleName'] = result[module].ModuleName;
          let access = result[module].AccessDetails;
          let hasAccessCount = 0;
          let accessType = {};
          for (let Moduleaccess = 0; Moduleaccess < access.length; Moduleaccess++) {
            accessType[result[module].AccessDetails[Moduleaccess].AccessTypeDescription] = false;
            if (result[module].AccessDetails[Moduleaccess].AccessStatus === 1) {
              hasAccessCount += 1;
              roleObj['enableModule'] = true;
              accessType[result[module].AccessDetails[Moduleaccess].AccessTypeDescription] = true;
            }
          }
          roleObj['Access'] = accessType;
          if (hasAccessCount === 0) {
            roleObj['enableModule'] = false;
          }
          roleArray.push(roleObj);
        }
        action({
          type: 'Common_Others',
          payload: {
            value:
              roleArray.filter((ele) => ele.enableModule === false).length === roleArray.length
                ? false
                : true,
            name: 'hasAccessToPrcds',
          },
        });
        if (roleArray.filter((ele) => ele.enableModule === false).length === roleArray.length) {
          throw new Error('No role assigned');
        } else {
          SetProfileCheckDone(true);
        }
        action({
          type: 'Common_Others',
          payload: {
            value: roleArray,
            name: 'userRoleDetail',
          },
        });
      })
      .catch((err) => {
        console.log(err);
        SetShowSpinner(false);
        SetMessage({
          open: true,
          title: t('user_profile'),
          body: t('norole_assigned'),
        });
      });
  };
  const updateDetails = (propName, propValue) => {
    action({
      type: 'updateTabProperty',
      payload: {
        tabName: 'General',
        propName: propName,
        propValue: propValue,
      },
    });
    if (propName === 'SessionTimeout') {
      action({
        type: 'Set_User_Active_time',
        payload: { value: propValue.Value },
      });
    }
  };
  useEffect(() => {
    if (ProfileCheckDone) {
      SetShowSpinner(true);
      (async () => {
        let fdroList = [];
        let Fdro = process.env.REACT_APP_FDRO_CODE;
        action({
          type: 'Update_User_Detail',
          payload: {
            value: { FDRO: process.env.REACT_APP_FDRO_CODE },
          },
        });
        await FDROService.getAllCategory()
          .then((res) => {
            fdroList = res.data.officeSettingTypeCategory;
            action({
              type: 'updateTab',
              payload: {
                tabName: 'masterData',
                tabValue: res.data.officeSettingTypeCategory.reduce(
                  (obj, item) =>
                    Object.assign(obj, {
                      [item.SettingTypeCategoryDescription]: {
                        primaryCode: item.SettingTypeCategoryCode,
                      },
                    }),
                  {}
                ),
              },
            });
          })
          .catch(() => {});
        let generalID = fdroList.find((el) => el.SettingTypeCategoryDescription === 'General');
        await FDROService.getOfficeSettingType(generalID ? generalID.SettingTypeCategoryCode : 1)
          .then((res) => {
            action({
              type: 'updateTabProperty',
              payload: {
                tabName: 'masterData',
                propName: 'General',
                propValue: {
                  primaryCode: generalID.SettingTypeCategoryCode || 1,
                  ...res.data.officeSettingType.reduce(
                    (obj, item) =>
                      Object.assign(obj, { [item.SettingTypeDescription]: item.SettingTypeCode }),
                    {}
                  ),
                },
              },
            });
          })
          .catch(() => {});
        let reservationId = fdroList.find(
          (el) => el.SettingTypeCategoryDescription === 'Reservation'
        );
        await FDROService.getOfficeSettingType(
          reservationId ? reservationId.SettingTypeCategoryCode : 5
        )
          .then((res) => {
            action({
              type: 'updateTabProperty',
              payload: {
                tabName: 'masterData',
                propName: 'Reservation',
                propValue: {
                  primaryCode: generalID.SettingTypeCategoryCode || 1,
                  ...res.data.officeSettingType.reduce(
                    (obj, item) =>
                      Object.assign(obj, { [item.SettingTypeDescription]: item.SettingTypeCode }),
                    {}
                  ),
                },
              },
            });
          })
          .catch(() => {});
        let tabResponse = {};
        tabResponse = await FDROService.getFdroGroupTabsData(
          `fundraisingOfficeSettings/${Fdro}/category/1`
        );
        if (tabResponse.isAxiosError) {
          updateDetails('ApplicationDateFormat', { Value: 'DD/MM/YYYY' });
          updateDetails('ApplicationTimeFormat', { Value: 'HH:MM:SS:XM' });
          updateDetails('CRMIntegration', { Value: 'No' });
          updateDetails('CountryGroupLimit', { Value: '5' });
          updateDetails('ProjectCategoryLimit', { Value: '5' });
          updateDetails('ProjectCategoryRatingLevelLimit', { Value: '5' });
          updateDetails('SponsorTypeLimit', { Value: '5' });
          updateDetails('PrivacyPolicy', { Value: '7' });
          updateDetails('ApplicationTimeZone', { Value: '(GMT-08:00) Pacific Time (US & Canada)' });
          updateDetails('SessionTimeout', { Value: '10' });
          SetApiCalldone(true);
        } else {
          let details = tabResponse.data.Settings;
          let dateFormatIndex = details.findIndex(
            (x) => x.SettingTypeDescription === 'Application Date Format'
          );
          let timmeFormatIndex = details.findIndex(
            (x) => x.SettingTypeDescription === 'Application Time Format'
          );
          let crmIntegration = details.findIndex(
            (x) => x.SettingTypeDescription === 'CRM Integration'
          );
          let cgLimit = details.findIndex(
            (x) => x.SettingTypeDescription === 'Country Group Limit'
          );
          let pgLimit = details.findIndex(
            (x) => x.SettingTypeDescription === 'Project Category Limit'
          );
          let pgRatingLimit = details.findIndex(
            (x) => x.SettingTypeDescription === 'Project Category Rating Level Limit'
          );
          let sponserType = details.findIndex(
            (x) => x.SettingTypeDescription === 'Sponsor Type Limit'
          );
          let privacyPolicy = details.findIndex(
            (x) => x.SettingTypeDescription === 'Privacy Policy'
          );
          let timeZone = details.findIndex(
            (x) => x.SettingTypeDescription === 'Application Time Zone'
          );
          let sesstionTime = details.findIndex(
            (x) => x.SettingTypeDescription === 'Session Timeout'
          );
          if (crmIntegration != -1) {
            updateDetails('CRMIntegration', details[crmIntegration]);
          } else {
            updateDetails('CRMIntegration', { Value: 'No' });
          }
          if (dateFormatIndex != -1) {
            updateDetails('ApplicationDateFormat', details[dateFormatIndex]);
          } else {
            updateDetails('ApplicationDateFormat', { Value: 'DD/MM/YYYY' });
          }
          if (timmeFormatIndex != -1) {
            updateDetails('ApplicationTimeFormat', details[timmeFormatIndex]);
          } else {
            updateDetails('ApplicationTimeFormat', { Value: 'HH:MM:SS:XM' });
          }
          if (cgLimit != -1) {
            updateDetails('CountryGroupLimit', details[cgLimit]);
          } else {
            updateDetails('CountryGroupLimit', { Value: '5' });
          }
          if (pgLimit != -1) {
            updateDetails('ProjectCategoryLimit', details[pgLimit]);
          } else {
            updateDetails('ProjectCategoryLimit', { Value: '5' });
          }
          if (pgRatingLimit != -1) {
            updateDetails('ProjectCategoryRatingLevelLimit', details[pgRatingLimit]);
          } else {
            updateDetails('ProjectCategoryRatingLevelLimit', { Value: '5' });
          }
          if (sponserType != -1) {
            updateDetails('SponsorTypeLimit', details[sponserType]);
          } else {
            updateDetails('SponsorTypeLimit', { Value: '5' });
          }
          if (privacyPolicy != -1) {
            updateDetails('PrivacyPolicy', details[privacyPolicy]);
          } else {
            updateDetails('PrivacyPolicy', { Value: '7' });
          }
          if (timeZone != -1) {
            updateDetails('ApplicationTimeZone', details[timeZone]);
          } else {
            updateDetails('ApplicationTimeZone', {
              Value: '(GMT-08:00) Pacific Time (US & Canada)',
            });
          }
          if (sesstionTime != -1) {
            updateDetails('SessionTimeout', details[sesstionTime]);
          } else {
            updateDetails('SessionTimeout', { Value: '10' });
          }
          SetApiCalldone(true);
          SetShowSpinner(false);
        }
      })();
    }
  }, [ProfileCheckDone]);
  useEffect(() => {
    action({
      type: 'Update_User_Detail',
      payload: {
        value: { FDRO: process.env.REACT_APP_FDRO_CODE },
      },
    });
  }, []);
  const CloseDialog = () => {
    window.localStorage.clear();
    window.location.assign(`${process.env.REACT_APP_IDP_URL}/logout`);
  };
  const renderFooter = () => {
    return (
      <Button
        className="p-button-outlined rcds-close-btn"
        label={t('close')}
        onClick={CloseDialog}
      />
    );
  };
  return (
    <>
      <Switch>
        <Route exact path="/auth" component={OidcAuthComponent} />
        <Route exact path="/accessDeniedError" component={AccessDeniedPage} />
        <Route exact path="/unauthorized" component={UnauthorizedScreen} />
      </Switch>

      <BlockUI
        blocked={ShowSpinner}
        template={
          <>
            <ThreeDots color="#FF6B00" height={80} width={80} ariaLabel="Loading" />
          </>
        }
        fullScreen
      >
        <div className="App">
          {ApiCallDone && (
            <>
              <IdleTimeComponent></IdleTimeComponent>
              <AppRouting />
              {userReduxData.hasAccessToPrcds && <LegalConsent />}
            </>
          )}
        </div>
        <Dialog
          header={Message.title}
          visible={Message.open}
          style={{ width: '35vw' }}
          breakpoints={{ '1000px': '70vw', '500px': '95vw' }}
          footer={renderFooter}
          onHide={CloseDialog}
          closable={false}
        >
          <p>{Message.body}</p>
        </Dialog>
      </BlockUI>
    </>
  );
};

export default App;
