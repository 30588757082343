import React, { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import '../CSS/LandingPage.css';
function PrcdsSkeleton() {
  return (
    <div className="parent-skeleton">
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  );
}
export default PrcdsSkeleton;
