const initialState = {
  region: sessionStorage.getItem('apiRedRegion') ? sessionStorage.getItem('apiRedRegion') : [],
  useroffice: sessionStorage.getItem('apiRedUseroffice')
    ? JSON.parse(sessionStorage.getItem('apiRedUseroffice'))
    : [],
  selectedUserOffice: sessionStorage.getItem('apiRedSelecuseroffice')
    ? JSON.parse(sessionStorage.getItem('apiRedSelecuseroffice'))
    : [],
  comments: '',
  apiurl: '',
  apidescription: '',
  sousername: '',
  sopassword: '',
  adminUserPassword: '',
  adminUserName: '',
  regionOptions: [],
  showAgGrid: false,
  childDataToBeUpdated: {
    input: '',
    dropDown: '',
  },
  childDataToBeReviewedIn: {
    input: '',
    dropDown: '',
  },
  childDataToBeRenewedIn: {
    input: '',
    dropDown: '',
  },
  childMediaToBeUpdatedIn: {
    input: '',
    dropDown: '',
  },
  childHoldPeriod: {
    input: '',
    dropDown: '',
  },
  reservationToBeRenewIn: {
    input: '',
    dropDown: '',
  },
  childAgeSponsorTer: {
    input: '',
    dropDown: '',
  },
  reservationAutoExpiryIn: {
    input: '',
    dropDown: '',
  },
};
const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'onClearAPIData':
      return {
        ...state,
        region: '',
        selectedUserOffice: [],
        useroffice: [],
        apiurl: '',
        apidescription: '',
        comments: '',
        sousername: '',
        sopassword: '',
        adminUserPassword: '',
        adminUserName: '',
        showAgGrid: false,
      };
    case 'fetchRegionUsername':
      return {
        ...state,
        region: action.payload.setRegionAction,
        selectedUserOffice: action.payload.setSelectedUserOfficeAction,
        useroffice: action.payload.setUserOfficeAction,
      };
    case 'fetchSelectedUsername':
      return {
        ...state,
        selectedUserOffice: action.payload.selectedUserOfficeAction,
      };
    case 'onAPIChange':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'onUserNamePassChange':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'updateRegionOptions':
      return {
        ...state,
        regionOptions: action.payload.setRegionOptions,
      };
    case 'agGridTable':
      return {
        ...state,
        showAgGrid: action.payload.setShowAgGrid,
      };
    case 'clearSupportOfficeFields':
      return {
        ...state,
        childDataToBeUpdated: {
          input: '',
          dropDown: '',
        },
        childDataToBeReviewedIn: {
          input: '',
          dropDown: '',
        },
        childDataToBeRenewedIn: {
          input: '',
          dropDown: '',
        },
        childMediaToBeUpdatedIn: {
          input: '',
          dropDown: '',
        },
        childHoldPeriod: {
          input: '',
          dropDown: '',
        },
        reservationToBeRenewIn: {
          input: '',
          dropDown: '',
        },
        childAgeSponsorTer: {
          input: '',
          dropDown: '',
        },
        reservationAutoExpiryIn: {
          input: '',
          dropDown: '',
        },
      };
    case 'supportOfficeSettingsOCMUpdate':
      var finalObj = state[action.payload.name];
      finalObj.input = action.payload.value;
      return {
        ...state,
        [action.payload.name]: finalObj,
      };
    case 'supportOfficeSettingsOCMDDUpdate':
      var finalObjDD = state[action.payload.ddname];
      finalObjDD.dropDown = action.payload.value;
      return {
        ...state,
        [action.payload.ddname]: finalObjDD,
      };
    default:
      return state;
  }
};
export default apiReducer;
