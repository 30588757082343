import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import '../CSS/LegalConsent.css';
import leaf from '../../../../assets/images/SVG-logo.png';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { loginFunction } from '../Logic/commonFunction';
import { ThreeDots } from 'react-loader-spinner';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function LegalConsent() {
  const [show, setShow] = useState(false);
  const Toaster = useRef();
  const [showSpinner, setShowSpinner] = useState(false);
  const reduxData = useSelector((state) => state.fdroSettingReducer.General);
  const masterData = useSelector((state) => state.fdroSettingReducer.masterData);
  const [DisableBtn, SetDisableBtn] = useState(true);
  const commonRedux = useSelector((state) => state.LoginReducer);
  const LoginData = useSelector((data) => data.LoginReducer);
  const [DocumentData, SetDocumentData] = useState('');
  const [numPages, setNumPages] = useState(0);
  const Fdro = LoginData.userDetail.FDRO || 'USAO';
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const formURL = (url) => {
    if (url && url.length !== 0) {
      location.search = url;
    } else {
      url = location.pathname.substring(location.pathname.lastIndexOf('?'));
      location.search = url;
    }
    return url;
  };
  const getParams = useMemo(() => new URLSearchParams(formURL(location.search)), [location.search]);
  const renderFooter = (name) => {
    return (
      <div style={{ width: '100%' }}>
        <Button
          label={t('i_accept')}
          className="p-button-warning"
          onClick={acceptHandler}
          varient="primary"
          autoFocus
          disabled={DisableBtn}
        />
      </div>
    );
  };
  const acceptHandler = () => {
    let profile = JSON.parse(localStorage.getItem('profile') || {});
    let body = {
      PolicyAcknowledgement: {
        FDRO: commonRedux.policyStatement ? commonRedux.policyStatement.FDRO : Fdro,
        UserEmail: profile.email,
        PrivacyPolicyId: commonRedux.policyStatement
          ? commonRedux.policyStatement.PrivacyPolicyId
          : 1,
        IPAddress: '10.23.24.58',
      },
    };
    
    setShowSpinner(true);
    loginFunction
      .savePrivacyPolicy(body)
      .then((res) => {
        setCookie();
        setShow(false);
      })
      .catch((err) => {
        Toaster.current.show({
          severity: 'error',
          detail: 'Error while saving data',
          life: 2000,
        });
        console.log(err);
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };
  const setCookie = (statement, AkId = undefined, acceptedOn, silent = false) => {
    let profile = JSON.parse(localStorage.getItem('profile') || {});
    let storeData = {
      user: profile.email,
      acceptedOn: new Date().toJSON(),
      policyVersion:
        commonRedux.policyStatement.PrivacyPolicyVersion || statement.PrivacyPolicyVersion,
      PrivacyPolicyId: commonRedux.policyStatement.PrivacyPolicyId || statement.PrivacyPolicyId,
    };
    let dayLimit = reduxData['PrivacyPolicy'] && reduxData['PrivacyPolicy'].Value;
    dayLimit = +dayLimit || 7;
    let expiryAt = silent
      ? moment(acceptedOn).add(dayLimit, 'days').hours(0).minutes(0).seconds(0)
      : moment().add(dayLimit, 'days').hours(0).minutes(0).seconds(0); 
    let expires = 'expires=' + expiryAt.format('ddd, DD MMM YYYY') + ' 00:00:00 GMT';
    document.cookie =
      'PRCDSLegalConsent' + '=' + JSON.stringify(storeData) + ';' + expires + ';path=/';
  };
  function getCookie(name) {
    var cname = name + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
    
      if (c.indexOf(cname) == 0) {
        let cookieData = c.substring(cname.length, c.length);
        try {
          let userProfile = localStorage.getItem('profile');
          userProfile = JSON.parse(userProfile);
          cookieData = JSON.parse(cookieData);
          if (userProfile.email === cookieData.user) {
            return c.substring(cname.length, c.length);
          }
          return '';
        } catch (err) {
          return '';
        }
      }
    }
    return '';
  }
  const getStatement = async () => {
    let result = {};
    if (Object.values(commonRedux.policyStatement).length === 0) {
      setShowSpinner(true);
      await loginFunction
        .getPolicyStatement(Fdro)
        .then((res) => {
          SetDocumentData(res.data.PolicyDocument.PolicyDocumentContent);
          delete res.data.PolicyDocument.PolicyDocumentContent;
          dispatch({
            type: 'Common_Others',
            payload: {
              name: 'policyStatement',
              value: res.data.PolicyDocument,
            },
          });
          result = res.data.PolicyDocument || {};
        })
        .catch((e) => {
          console.log(e);
          Toaster.current.show({
            severity: 'error',
            detail: t('error_fetching_statement'),
            life: 2000,
          });
          result = {};
        });
    } else {
      result = commonRedux.policyStatement;
    }
    return result;
  };
  const getFdroPrivacyPolicy = async () => {
    let result = {
      Value: 7,
    };
    if (Object.keys(reduxData).length === 0 || reduxData['PrivacyPolicy'] === undefined) {
      await loginFunction
        .getFdroPolicy(1, masterData.General['Privacy Policy'])
        .then((res) => {
          result = res.data.Settings[0];
        })
        .catch(() => {
          Toaster.current.show({
            life: 2000,
            severity: 'error',
            detail: 'Error fetching FDRO data, so default data setted as 7 days',
          });
          console.log('Error while fetching fdro data');
        });
    } else {
      result = reduxData['PrivacyPolicy'];
    }
    return result;
  };
  useEffect(() => {
    if (getParams.get('type') === null || getParams.get('type') === undefined) {
    
      (async () => {
        let cookieData = getCookie('PRCDSLegalConsent');
        let statementDetail = await getStatement(); //for mismatch policy document - case 1
        let currentPolicyVersion = statementDetail.PrivacyPolicyId || 1;
        currentPolicyVersion = +currentPolicyVersion;
        if (cookieData.length === 0) {
          let stateFlag = false;
          setShowSpinner(true);
          loginFunction
            .getPolicyAcknowledgement(Fdro)
            .finally(() => {
              setShowSpinner(false);
            })
            .then((res) => {
              if (res.data.PolicyAcknowledgement.length !== 0) {
                currentPolicyVersion === +res.data.PolicyAcknowledgement[0].PrivacyPolicyId
                  ? (stateFlag = false)
                  : (stateFlag = true);
                if (!stateFlag) {
                  (async () => {
                    let acceptedDate = moment(res.data.PolicyAcknowledgement[0].AcknowlegementDate)
                      .hours(0)
                      .minutes(0)
                      .seconds(0);
                    let differ = moment().diff(acceptedDate, 'days');
                    let reduxValue = await getFdroPrivacyPolicy();
                    reduxValue = reduxValue ? reduxValue.Value : 7;
                    if (differ >= +reduxValue) {
                      stateFlag = true;
                    } else {
                      stateFlag = false;
                      setCookie(
                        statementDetail,
                        res.data.PolicyAcknowledgement[0].AcknowlegementId,
                        acceptedDate,
                        true
                      );
                    }
                    setShow(stateFlag);
                  })();
                }
                setShow(stateFlag);
                dispatch({
                  type: 'Common_Others',
                  payload: {
                    name: 'policyAcknowledgement',
                    value: res.data.PolicyAcknowledgement[0],
                  },
                });
              } else {
                stateFlag = true;
                setShow(stateFlag);
              }
            })
            .catch((err) => {
              console.log(err);
              setShow(true);
            });
        } else {
          setShowSpinner(false);
          let stateFlag = false;
          let parsedCookie = JSON.parse(cookieData || '{}');
          parsedCookie.PrivacyPolicyId === currentPolicyVersion
            ? (stateFlag = false)
            : (stateFlag = true);
          setShow(stateFlag);
          
        }
      })();
    } else {
      setShow(false);
    }
  }, []);
  useEffect(() => {
    show && history.replace('/');
  }, [show]);
  const renderHeader = () => {
    return (
      <div className="p-d-flex" style={{ gridGap: '10px' }}>
        <img src={leaf} style={{ width: '20px' }} />
        <h4 className="p-mb-0 p-text-bold">{t('terms_of_use')}</h4>
      </div>
    );
  };
  const onDocumentLoadSuccess = (e) => {
    setNumPages(e.numPages);
    SetDisableBtn(false);
  };
  const onDocumentLoadError = () => {
    console.log('error on pdf load');
    Toaster.current.show({
      severity: 'error',
      detail: t('error_fetching_statement'),
      life: 2000,
    });
  };
  return (
    <BlockUI
      blocked={showSpinner}
      template={
        <>
          <ThreeDots color="#FF6B00" height={80} width={80} ariaLabel="Loading" />
        </>
      }
      fullScreen
    >
      <Toast ref={Toaster} position="bottom-left" />
      <Dialog
        header={renderHeader}
        autoFocus
        visible={show}
        // visible={true}
        breakpoints={{ '960px': '95%', '640px': '95%', '1000px': '95%' }}
        style={{ width: '50vw', height: '90vh' }}
        footer={renderFooter}
        draggable={false}
        closable={false}
        className="legal-popup"
      >
        {DocumentData.length !== 0 ? (
         
          <Document
            file={`data:application/pdf;base64,${DocumentData}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            onSourceError={onDocumentLoadError}
            className="policy-pdf"
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                scale={1}
              />
            ))}
          </Document>
        ) : (
          <ThreeDots color="#FF6B00" height={80} width={80} ariaLabel="Loading" />
        )}
      </Dialog>
    </BlockUI>
  );
}
export default LegalConsent;
