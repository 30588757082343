import React, { useCallback, Suspense, useEffect, useState, useRef, memo, useMemo } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import SessionOut from '../component/Module/SSO/UI/SessionOut';
import PropTypes from 'prop-types';
import { loginFunction } from '../component/Module/SSO/Logic/commonFunction';
import { parse } from 'query-string';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';
import LegalConsent from '../component/Module/SSO/UI/legalConsent';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import WvLanguage from '../component/Common/Multilingual/WvLanguage';
function ProtectedRoute({ component: Component, ...rest }) {
  const { t } = WvLanguage();
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();
  const state = useSelector((state) => state.LoginReducer);
  const dispatch = useDispatch();
  const [ShowDialog, SetShowDialog] = useState(false);
  const RouteRef = useRef(false);

  const getParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const toast = useRef();
  useEffect(() => {
    if (
      state.isExportEnabled !== undefined &&
      toast.current !== undefined &&
      state.isExportEnabled === true
    ) {
      let role = state.toastVarient;
      toast.current.clear();
      setTimeout(() => {
        toast.current.show({ severity: role, detail: state.toastMessage, life: 5000 });
      }, 200);
    }
    if (
      state.isExportEnabled !== undefined &&
      toast.current !== undefined &&
      state.isExportEnabled === false
    ) {
      toast.current && toast.current.clear();
    }
  });

  const checkIsValidRoute = () => {
    let haveAccess = false;
    if (loginFunction.isTokenExist('oneLoginDetails') || getParams.get('type')) {
      if (state.hasAccessToPrcds) {
        let findModule = state.userRoleDetail.find(
          (ele) => ele.ModuleName === rest.identifier.module
        );
        if (rest.identifier.module === 'Home') {
          haveAccess = true;
        } else {
          let mode = getParams.get('mode');
          if (mode) {
            mode = mode.charAt(0).toUpperCase() + '' + mode.substring(1);
            mode = mode.replace('Transfer', 'Edit');
            haveAccess = findModule.Access[mode];
          } else {
            haveAccess = findModule.Access[rest.identifier.mode];
          }
        }
        return haveAccess;
      }
      return haveAccess;
    } else {
      return haveAccess;
    }
  };
  const closeToast = () => {
    toast.current.clear();
    dispatch({
      type: 'Export_To_Excel',
      payload: { value: false, message: '' },
    });
  };
  const renderFooter = () => {
    return (
      <Button
        className="p-button-outlined rcds-close-btn"
        label={t('close')}
        onClick={CloseDialog}
      />
    );
  };
  const CloseDialog = () => {
    window.localStorage.clear();
    window.location.assign(`${process.env.REACT_APP_IDP_URL}/logout`);
  };
  return checkIsValidRoute() ? (
    <Route {...rest}>
      <>
        <Toast ref={toast} position="bottom-left" onHide={() => closeToast()} />
        <Component />
      </>
    </Route>
  ) : (
    <>
      {loginFunction.isTokenExist('oneLoginDetails') || (
        <Route
          component={() =>
            getParams.get('type') ? <Redirect to="/accessDeniedError" /> : <Redirect to="/auth" />
          }
        />
      )}
      {loginFunction.isTokenExist('oneLoginDetails') && <Redirect to="/accessDeniedError" />}
    </>
  );
}
ProtectedRoute.propTypes = {
  component: PropTypes.object,
};
export default memo(ProtectedRoute);
