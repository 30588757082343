const initialState = {
  basicSearch: {
    Project: [],
    Community: [],
    Country: [],
    ProjectStartDate: {
      Operator: '',
      From: '',
      To: '',
    },
    ProjectEndDate: {
      Operator: '',
      From: '',
      To: '',
    },
    ProjectStatus: [
      {
        name: 'Active',
        code: 'Active',
      },
    ],
    Region: [],
    FundingOffice: [],
    ImplementingOffice: [],
  },
  showGrid: false,
  isButtonEnabled: true,
  selectedProjectId: '',
  dropDownValue: {
    Community: [],
    Country: [],
    Project: [],
    ProjectStatus: [],
    Operator: [],
    Region: [],
    FundingOffice: [],
    ImplementingOffice: [],
  },
  AdvancedSearch: {},
  AdvancedSearchOptions: {},
  projectCategory: [],
  isUnsaveChangeExist: false,
  showUnsaveModel: false,
  activeTab: 0,
  nextTab: 0,
  assignUnsave: false,
  singleEmptyField: false,
  multiEmptyField: false,
  projectBackClick: false,
  projectLock: {
    ProjectLockStart: '',
    SponsorShipLockDate: '',
    ProjectLockEnd: '',
    ProjectLockStatus: 'Not Started',
    ProjectLockCountry: [],
    ProjectLockRegion: '',
    ProjectLockProject: [],
  },
};
const checkButtonStatus = (state, action) => {
  var checkStatus = { ...action };
  let allKeys = Object.keys(checkStatus);
  var flag = false;
  for (let i = 0; i < allKeys.length; i++) {
    if (allKeys[i] != 'isButtonEnabled') {
      if (typeof checkStatus[allKeys[i]] == 'object') {
        if (Array.isArray(checkStatus[allKeys[i]])) {
          flag = checkStatus[allKeys[i]].length == 0 ? false : true;
          if (flag) {
            break;
          }
        } else {
          flag = Object.values(checkStatus[allKeys[i]]).filter((x) => Boolean(x));
          flag = flag.length != 0 ? true : false;
          if (flag) {
            break;
          }
        }
      } else {
        checkStatus[allKeys[i]] = checkStatus[allKeys[i]]
          ? checkStatus[allKeys[i]].toString().trim()
          : '';
        flag = Boolean(checkStatus[allKeys[i]]) ? true : false;
        if (flag) {
          break;
        }
      }
    }
  }
  if (
    state['dropDownValue']['ProjectStatus'].length === 0 &&
    checkStatus['ProjectStatus'].length === 0
  ) {
    flag = true;
  }
  return flag;
};
const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'onChange':
      let propName = action['payload'].name;
      let propParent = action['payload'].parent;
      let emptyFieldName = action['payload'].doEmpty;
      let finalObj = { ...state['basicSearch'] };
      let dropDownValue = { ...state['dropDownValue'] };
      if (propParent) {
        finalObj[propParent][propName] = action['payload'].value;
        if (propParent == 'ProjectStartDate' || propParent == 'ProjectEndDate') {
          if (
            propName === 'From' ||
            (propName === 'Operator' && action['payload'].value.length <= 2)
          ) {
            finalObj[propParent]['To'] = '';
          }
          if (propName === 'Operator' && action['payload'].value.length === 0) {
            finalObj[propParent]['From'] = '';
          }
        }
      } else {
        finalObj[propName] = action['payload'].value;
      }
      if (emptyFieldName && emptyFieldName.length > 0) {
        finalObj[emptyFieldName] = [];
        dropDownValue[emptyFieldName] = [];
        if (propName === 'Country') {
          finalObj['Community'] = [];
          dropDownValue['Community'] = [];
        }
        if (propName === 'Region') {
          finalObj['Project'] = [];
          dropDownValue['Project'] = [];
          finalObj['Community'] = [];
          dropDownValue['Community'] = [];
        }
      }
      sessionStorage.setItem(
        'BasicProjectSearch',
        JSON.stringify({ ...finalObj, ...state.AdvancedSearch })
      );
      return {
        ...state,
        basicSearch: finalObj,
        dropDownValue,
        isButtonEnabled: checkButtonStatus(state, { ...finalObj, ...state.AdvancedSearch }),
      };
    case 'Advanced_search_onChange':
      let adsPropName = action['payload'].name;
      let adsPropParent = action['payload'].parent;
      let adsEmptyFieldName = action['payload'].doEmpty;
      let adsFinalObj = { ...state['AdvancedSearch'] };
      let basicSearch = { ...state['basicSearch'] };
      let adsDropDownValue = { ...state['dropDownValue'] };
      if (adsPropParent) {
      } else {
        adsFinalObj[adsPropName] = action['payload'].value;
      }

      basicSearch['Project'] = [];
      basicSearch['Community'] = [];
      adsDropDownValue['Project'] = [];
      adsDropDownValue['Community'] = [];
      sessionStorage.setItem(
        'BasicProjectSearch',
        JSON.stringify({ ...basicSearch, ...adsFinalObj })
      );
      return {
        ...state,
        basicSearch,
        AdvancedSearch: adsFinalObj,
        dropDownValue: adsDropDownValue,
        isButtonEnabled: checkButtonStatus(state, { ...adsFinalObj, ...basicSearch }),
      };
    case 'vp_Search_Change':
      return {
        ...state,
        basicSearch: action.data,
        isButtonEnabled: checkButtonStatus(state, action.data),
      };
    case 'Add_Project':
      let constructNewOne = action.payload.makeCopy
        ? { ...state['AdvancedSearch'], ...action.payload.adsObj }
        : action.payload.adsObj;
      return {
        ...state,
        AdvancedSearch: { ...constructNewOne },
      };
    case 'Project_Advanced_search_option':
      let allOptions = state.AdvancedSearchOptions;
      allOptions[action.payload.name] = action.payload.value;
      return {
        ...state,
        AdvancedSearchOptions: allOptions,
      };
    case 'Project_onClear':
      let dropDownValues = { ...state['dropDownValue'] };
      dropDownValues['Project'] = [];
      dropDownValues['Community'] = [];
      dropDownValues['Country'] = [];
      return {
        ...state,
        basicSearch: {
          Project: [],
          Community: [],
          Country: [],
          ProjectStartDate: {
            Operator: '',
            From: '',
            To: '',
          },
          ProjectEndDate: {
            Operator: '',
            From: '',
            To: '',
          },
          Region: [],
          FundingOffice: [],
          ImplementingOffice: [],
          ProjectStatus: [
            {
              name: 'Active',
              code: 'Active',
            },
          ],
        },
        AdvancedSearch: {},
        AdvancedSearchOptions: {},
        isButtonEnabled: true,
        showGrid: false,
        dropDownValue: dropDownValues,
      };
    case 'project_others':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'default_value_load_project':
      let dv = JSON.parse(JSON.stringify(state.dropDownValue));
      dv[action.payload.name] = action.payload.value;
      return {
        ...state,
        dropDownValue: dv,
      };
    case 'project_lock_onChange':
      let { name, value, doEmpty } = action.payload;
      let copyOby = { ...state.projectLock };
      copyOby[name] = value;
      let dropDownOption = state.dropDownValue;
      if (doEmpty) {
        copyOby[doEmpty] = name === 'ProjectLockStart' ? '' : [];
        if (name === 'ProjectLockRegion') {
          dropDownOption['Country'] = [];
          copyOby['ProjectLockProject'] = [];
        }
        if (name === 'ProjectLockCountry') dropDownOption['Project'] = [];
      }
      return {
        ...state,
        projectLock: copyOby,
      };
    case 'projectLockDropdown':
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    default:
      return state;
  }
};
export default projectReducer;
