import React, { useEffect, useState, memo } from 'react';
import '../CSS/SessionOut.css';
import ReactDOM from 'react-dom';
import { Button } from 'primereact/button';
import WvLanguage from '../../../Common/Multilingual/WvLanguage';
import { loginFunction } from '../Logic/commonFunction';
import { useHistory } from 'react-router-dom';
import { BlockUI } from 'primereact/blockui';
import { ThreeDots } from 'react-loader-spinner';
const modalRoot = document.getElementById('root-modal');
function SessionOut(props) {
  const { t } = WvLanguage();
  const history = useHistory();
  const { getTimeDifferent, refreshToken } = loginFunction;
  const [timer, setTimer] = useState(getTimeDifferent());
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, timer);
  }, []);
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds}`;
  }
  const stay = () => {
    if (timer === -1) {
      window.location.assign('https://wvi.onelogin.com/oidc/2/logout');
    } else {
      setLoader(true);
      refreshToken()
        .then((success) => {
          setIsOpen(false);
          setLoader(false);
        })
        .catch((err) => {});
    }
  };
  function cancelEvent() {
    setIsOpen(false);
  }
  return (
    isOpen &&
    ReactDOM.createPortal(
      <BlockUI
        blocked={loader}
        fullScreen
        template={<ThreeDots color="#FF6B00" height={80} width={80} />}
      >
        <div id="outModal">
          <div id="outModal_inner">
            <p className="p-text-left p-mb-3" style={{ fontWeight: '600' }}>
              {t('session_expire_alert_header')}
            </p>
            <div className="p-mb-2">
              <span className="pi pi-exclamation-triangle p-mr-1"></span>
              {timer >= 0 ? (
                <p className="p-mb-0">{t('expire_msg_without_timer')}</p>
              ) : (
                <p className="p-mb-0">{t('expire_msg')}</p>
              )}
            </div>
            <div className="d-flex p-jc-end" style={{ gap: '10px' }}>
              {timer >= 0 && (
                <Button
                  className="p-button-outlined p-button-secondary wv-cancel-btn"
                  label={t('cancel_button')}
                  onClick={() => cancelEvent()}
                />
              )}
              <Button
                className="p-button-warning"
                label={`${timer >= 0 ? t('stay_button') : t('relogin_button')}`}
                onClick={() => stay()}
                autoFocus
              />
            </div>
          </div>
        </div>
      </BlockUI>,
      modalRoot
    )
  );
}
export default memo(SessionOut);
