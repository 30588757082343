const initialState = {
  username: '',
  password: '',
  language: '',
  isExportEnabled: false,
  toastMessage: '',
  toastVarient: 'info',
  userActiveTime: 10,
  policyStatement: {},
  policyAcknowledgement: {},
  userDetail: {
    FirstName: '',
    LastName: '',
    Email: '',
    FDRO: '',
    region: '',
    UserId: '',
  },
  userLoggedIn: false,
  userRoleDetail:[],
  hasAccessToPrcds: false,
  lastRefeshAt:new Date().getTime()
};
const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'Set_Login_Detail':
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        language: action.payload.language,
      };
    case 'Update_User_Detail':
      return {
        ...state,
        userDetail: { ...state.userDetail, ...action.payload.value },
      };
    case 'Export_To_Excel':
      return {
        ...state,
        isExportEnabled: action.payload.value,
        toastMessage: action.payload.message,
        toastVarient: action.payload.varient,
      };
    case 'Set_User_Active_time':
      return {
        ...state,
        userActiveTime: action.payload.value,
      };
    case 'Common_Others':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
export default LoginReducer;
