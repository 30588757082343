import { stringify } from 'query-string';
import JWTInterceptor from '../../../../Interceptors/JWTInterceptor';
export const loginFunction = {
  extendSession: (cname, cvalue) => {
    let setStorage = window.localStorage.getItem('tokenInformation');
    let newDate = new Date();
    setStorage = JSON.parse(setStorage);
    setStorage['expireAt'] = newDate.setMinutes(newDate.getMinutes() + 60); //extends to 1 hrs
    window.localStorage.setItem('tokenInformation', JSON.stringify(setStorage));
  },
  isTokenExist: (sname) => {
    var isExist = localStorage.getItem(sname);
    return Boolean(isExist);
  },
  refreshToken: async () => {
    var Refresh_token = window.localStorage.getItem('oneLoginDetails');
    Refresh_token = JSON.parse(Refresh_token);
    let params = stringify({
      grant_type: 'refresh_token',
      refresh_token: Refresh_token.refresh_token,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
    let apiCall = await axios.post(`${process.env.REACT_APP_IDP_URL}/token`, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    try {
      let loginTime = new Date();
      loginTime = loginTime.setTime(loginTime.getTime() + 60 * 60 * 1000);
      const data = JSON.stringify({ ...apiCall.data, expireAt: loginTime });
      localStorage.setItem('access_token', apiCall.data.access_token);
      localStorage.setItem('oneLoginDetails', data);
      return 'success';
    } catch (err) {
      return new Promise((resolve, reject) => reject(err));
    }
  },
  getTimeDifferent: () => {
    let expireTime = window.localStorage.getItem('oneLoginDetails');
    if (Boolean(expireTime)) {
      expireTime = JSON.parse(expireTime);
      expireTime = expireTime.expireAt;
      expireTime = new Date(expireTime);
      let currentDate = new Date();
      let diff = expireTime - currentDate;
      if (diff > 0) {
        return diff;
      }
      return 0;
    }
    return -1;
  },
  getPolicyAcknowledgement: async (FDRO) => {
    let userInfo = localStorage.getItem('profile');
    try {
      userInfo = JSON.parse(userInfo);
    } catch (err) {
      userInfo = {};
    }
    let config = {
      headers: { user_email: userInfo.email },
      params: {
        FDRO,
      },
    };
    let result = await JWTInterceptor.get('user/policyAcknowledgement', config);
    return result;
  },
  getPolicyStatement: async (FDRO) => {
    let result = await JWTInterceptor.get(`fundraisingOfficeSettings/${FDRO}/policyDocument`)
    return result;
  },
  savePrivacyPolicy: async (body) => {
    let result = await JWTInterceptor.post(`user/policyAcknowledgement`, body);
    return result;
  },
  getFdroPolicy: async (categoryId = 1, typeCode = 73) => {
    let result = await JWTInterceptor.get(
      `fundraisingOfficeSettings/USAO/category/${categoryId}?settingType=${typeCode}`
    );
    return result;
  },
};
