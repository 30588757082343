import axios from 'axios';
import { stringify } from 'query-string';
import JWTPcrmInterceptor from '../../Interceptors/JWTPcrmInterceptor';
const PKCEAuthCodeFirstStep = async () => {
  let codeVerifier = createCodeVerifier(50);
  localStorage.setItem('code_verifier', codeVerifier);
  return await createCodeChallenge(codeVerifier);
};
const createCodeVerifier = (size) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
  const charsetIndexBuffer = new Uint8Array(size);
  for (let i = 0; i < size; i += 1) {
    charsetIndexBuffer[i] = (Math.random() * charset.length) | 0;
  }
  let randomChars = [];
  for (let i = 0; i < charsetIndexBuffer.byteLength; i += 1) {
    let index = charsetIndexBuffer[i] % charset.length;
    randomChars.push(charset[index]);
  }

  return randomChars.join('');
};
const OidcService = {
  getRefreshTokenWithCode: async (code) => {
    let oidcURL = `${process.env.REACT_APP_IDP_URL}/token`;
    let params = stringify({
      grant_type: 'authorization_code',
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      client_id: process.env.REACT_APP_CLIENT_ID,
      code_verifier: localStorage.getItem('code_verifier') || '',
      code,
    });
    localStorage.setItem('getRefreshToken', 'no');
    let result = await axios.post(oidcURL, params, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
    return result;
  },
  refreshPCRMToken: async (token) => {
    let result = await axios.post(`${process.env.REACT_APP_PCRM_API_BASE_URL}token`, {
      RefreshToken: token,
    });
    return result;
  },
};
export default OidcService;
