let errcnt = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
const initialStateVal = {
  CountryGroup: [],
  CountryInCountryGroup: [],
  General: {},
  RegisteredChildren: {},
  SponsorChildren: {},
  RC_errorvalue: {
    inputTextError: [],
    dropdownError: [],
    valueError: [],
    inputValError: [],
  },
  SC_errorvalue: {
    inputTextError: [false, false, false, false, false, false, false, false, false, false],
    dropdownError: [false, false, false, false, false, false, false, false, false, false],
    valueError: [false, false, false, false, false],
    inputValError: [false, false, false, false, false, false, false, false, false, false],
  },
  countryGroup_error: {
    groupNameEmptyError: errcnt,
    disEnabCounty: errcnt,
    errorMessage: errcnt,
    duplicateErrorMessage: errcnt,
  },
  generalDropDownValue: {
    ApplicationDateFormat: [],
    ApplicationTimeFormat: [],
    ChildDataHistory: [],
    AuditLog: [],
    ApplicationTimeZone: [],
  },
  reservationDropdown: [],
  projectCategory: [],
  reservation: [],
  sponsorType: [],
  activeTab: 0,
  notification: [],
  GeneralErrorMsg: {
    valueError: [],
    emptyError: [],
  },
  isUnsaveChangeExist: false,
  showUnsaveModel: false,
  unSaveChangeTabIndex: 0,
  currentLocation: '',
  masterData: {
    tabs: {},
  },
};

const fdroSettingReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case 'onFdroCountryGroupInputChange':
      // bringing entire state array by hard coding its value because if we dont use this redux is not rendering
      //  re adding the features
      const rest = JSON.parse(JSON.stringify(state));
      const group = action.payload;
      rest.CountryGroup = group;
      return rest;
    case 'countryDetailsInCountryGroup':
      state.CountryInCountryGroup = action.payload;
      return state;
    case 'cgDelete': {
      let originalData = JSON.parse(JSON.stringify(state.CountryGroup));
      originalData.splice(action.payload.index, 1);
      return {
        ...state,
        CountryGroup: originalData,
      };
    }
    case 'clearFDROData':
      return [...state];
    case 'updateTab':
      return {
        ...state,
        [action.payload.tabName]: action.payload.tabValue,
      };
    case 'updateTabWithCopy':
      return {
        ...state,
        [action.payload.tabName]: [...state[action.payload.tabName], action.payload.tabValue],
      };
    case 'updateTabProperty':
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };

    case 'Updateerrormsg':
      return {
        ...state,
        [action.payload.errorName]: {
          ...state[action.payload.errorName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case 'updateTabField':
      let updateData = [...state[action.payload.tabName]];
      let index = updateData.findIndex((ele) => ele.SettingTypeDescription == action.payload.code);
      updateData[index].Value = action.payload.value;
      return {
        ...state,
        [action.payload.tabName]: updateData,
      };
    case 'updateTabFieldUOM':
      let updateDataUOM = [...state[action.payload.tabName]];
      let indexUOM = updateDataUOM.findIndex(
        (ele) => ele.SettingTypeDescription == action.payload.code
      );
      updateDataUOM[indexUOM].UnitOfMeasureCd = action.payload.field1;
      updateDataUOM[indexUOM].UnitOfMeasureDescription = action.payload.field2;
      return {
        ...state,
        [action.payload.tabName]: updateDataUOM,
      };
    case 'generalDropdown':
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case 'generalErrorStore':
      return {
        ...state,
        [action.payload.errorName]: {
          ...state[action.payload.errorName],
          [action.payload.propName]: action.payload.propValue,
        },
      };

    case 'countryGroupErrorMessage':
      return {
        ...state,
        [action.payload.errorName]: {
          ...state[action.payload.errorName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case 'projectCategoryRatingUpdate':
      let original = [...state.projectCategory];
      let projectIndex = original.findIndex((ele) => ele.ProjectCategoryId === action.payload.id);
      original[projectIndex][action.payload.name] = action.payload.value;
      return {
        ...state,
        projectCategory: original,
      };
    case 'notificationChange':
      let { moduleId, notificationId, notificationKey, notificationValue } = action.payload;
      let copyNotification = [...state.notification];
      let ModuleIndex = copyNotification.findIndex((ele) => ele.ModuleName === moduleId);
      let childIndex = copyNotification[ModuleIndex].NotificationDetails.findIndex(
        (cele) => cele.NotificationId === notificationId
      );
      copyNotification[ModuleIndex].NotificationDetails[childIndex][notificationKey] =
        notificationValue;
      return {
        ...state,
        notification: copyNotification,
      };

    default:
      return state;
  }
};
export default fdroSettingReducer;
