import React, { useEffect } from 'react';
import WvLanguage from '../component/Common/Multilingual/WvLanguage';
import './AccessDeniedPage.css';
import AccessDeniedImage from '../assets/images/accessDenied.png';
const AccessDeniedPage = () => {
  const { t, changeLanguage } = WvLanguage();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="adDiv">
      <img src={AccessDeniedImage} alt="accessdenied" className="adImage" />
      <h2 className="pcrm_heading">{t('we_are_sorry_pcrm_error_page')}</h2>
      <p className="pcrmErrorMessage">{t('pcrm_error_message')}</p>
      <p>{t('refer_admin_pcrm')}</p>
    </div>
  );
};
export default AccessDeniedPage;
