import { combineReducers } from 'redux';
import projectReducer from '../component/Module/Project/Logic/Reducer';
import searchChildReducer from '../component/Module/Searchchild/logic/Reducer';
import LoginReducer from '../component/Module/SSO/Logic/Reducer';
import userAccessReducer from '../component/Module/UserAccess/Logic/USReducer';
import apiReducer from '../component/Module/Admin/Logic/APIReducer';
import reservationReducer from '../component/Module/CreateReservation/Logic/Reducer';
import importDataReducer from '../component/Module/ImportData/Logic/ImportDataReducer';
import viewChildReducer from '../component/Module/Viewchild/Logic/ViewChildReducer';
import { sendCsmReducer } from '../component/Module/SendCsm/Logic/Reducer';
import fdroSettingReducer from '../component/Module/FundRaisingOfficeSetting/Logic/FdroSettingReducer';
import roleReducer from '../component/Module/UserRole/Logic/RoleReducer';
import userGroupReducer from '../component/Module/UserGroup/Logic/UserGroupReducer';
const rootReducer = combineReducers({
  projectReducer,
  searchChildReducer,
  LoginReducer,
  userAccessReducer,
  apiReducer,
  reservationReducer,
  importDataReducer,
  viewChildReducer,
  sendCsmReducer,
  fdroSettingReducer,
  roleReducer,
  userGroupReducer,
});
export default rootReducer;
