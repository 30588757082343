import JWTInterceptor from '../../../../Interceptors/JWTInterceptor';
export const ProfileService = {
  getProfileDetail: async () => {
    let result = await JWTInterceptor.get('/user/searchUserByEmail');
    return result;
  },
  createProfile: async (body) => {
    let result = await JWTInterceptor.post('/user', body);
    return result;
  },
  getRoles: async (id) => {
    let result = await JWTInterceptor.get(`user/${id}/access`);
    return result;
  },
};