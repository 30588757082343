import AdvanceSearchCompontens from '../../../../Data/AdvanceSearchComponents.json';
const initialState = {
  BasicSearch: {
    ChildCode: '',
    ChildGivenName: '',
    ChildFamilyName: '',
    ChildCountryId: [],
    AgeRange: {
      Operator: '',
      From: '',
      To: '',
    },
    Gender: [],
    Project: [],
    Region: '',
    RCDSChildStatus: [],
    ReservationID: '',
    ActionCode: [],
    RCDSAddDate: {
      Operator: '',
      From: '',
      To: '',
    },
    FieldOfficeLastUpdateDate: {
      Operator: '',
      From: '',
      To: '',
    },
  },
  AdvancedSearch: {},
  AdvancedSearchOptions: {},
  isButtonEnabled: false,
  SelectedChildId: [],
  showGrid: false,
  PageName: { from: '', to: '' },
  dropDownValue: {
    RCDSChildStatus: [],
    ChildCountryId: [],
    Project: [],
    Region: [],
    Gender: [],
    ActionCode: [],
    Operator: [],
  },
  isCommunityDisable: false,
  doPcrmReset: false,
  // AdsFlag: false,
};
const checkButtonStatus = (state) => {
  let checkStatus = { ...state['BasicSearch'], ...state.AdvancedSearch };
  let allKeys = Object.keys(checkStatus);
  let flag = false;
  for (let i = 0; i < allKeys.length; i++) {
    if (allKeys[i] != 'isButtonEnabled') {
      if (typeof checkStatus[allKeys[i]] == 'object') {
        if (Array.isArray(checkStatus[allKeys[i]])) {
          flag = checkStatus[allKeys[i]].length == 0 ? false : true;
          if (flag) {
            break;
          }
        } else {
          flag = Object.values(checkStatus[allKeys[i]]).filter((x) => Boolean(x));
          flag = flag.length != 0 ? true : false;
          if (flag) {
            break;
          }
        }
      } else {
        flag = Boolean(checkStatus[allKeys[i]].trim()) ? true : false;
        if (flag) {
          break;
        }
      }
    }
  }

  return flag;
};

const searchChildReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'vc_Search_Change':
      return {
        ...state,
        BasicSearch: action.data,
        isButtonEnabled: checkButtonStatus(state),
      };
    case 'vc_Advance_Change':
      return {
        ...state,
        AdvancedSearch: action.data,
        isButtonEnabled: checkButtonStatus(state),
      };
    case 'csm_Search_Change':
      return {
        ...state,
        BasicSearch: action.data,
        isButtonEnabled: checkButtonStatus(state),
      };
    case 'csm_Advance_Change':
      return {
        ...state,
        AdvancedSearch: action.data,
        isButtonEnabled: checkButtonStatus(state),
      };
    case 'Search_Change':
      let propName = action.name;
      let propParent = action.parent;

      let dropDownValue = { ...state.dropDownValue };
      let AdvancedSearch = { ...state.AdvancedSearch };
      let AdvancedSearchOptions = { ...state.AdvancedSearchOptions };
      let finalObj = state['BasicSearch'];
      if (propParent) {
        finalObj[propParent][propName] = action.value;
        if (
          propParent === 'FieldOfficeLastUpdateDate' ||
          propParent === 'RCDSAddDate' ||
          propParent === 'AgeRange'
        ) {
          if (propName === 'From' || (propName === 'Operator' && action.value.length <= 2)) {
            finalObj[propParent]['To'] = '';
          }
          if (propName === 'Operator' && action.value.length === 0) {
            finalObj[propParent]['From'] = '';
          }
        }
      } else {
        finalObj[propName] = action.value;
        if (propName === 'Region') {
          finalObj['ChildCountryId'] = [];
          dropDownValue['ChildCountryId'] = [];
          finalObj['Project'] = [];
          dropDownValue['Project'] = [];
        }
        if (propName === 'ChildCountryId') {
          finalObj['Project'] = [];
          dropDownValue['Project'] = [];
          if ('Community' in AdvancedSearch) {
            AdvancedSearch['Community'] = [];
            AdvancedSearchOptions['Community'] = [];
          }
        }
        if (propName === 'Project' && 'Community' in AdvancedSearch) {
          AdvancedSearch['Community'] = [];
          AdvancedSearchOptions['Community'] = [];
        }
      }
      sessionStorage.setItem('vcBasicSearch', JSON.stringify(finalObj));
      sessionStorage.setItem('csmBasicSearch', JSON.stringify(finalObj));
      return {
        ...state,
        BasicSearch: finalObj,
        dropDownValue: dropDownValue,
        AdvancedSearch: AdvancedSearch,
        AdvancedSearchOptions: AdvancedSearchOptions,
        isButtonEnabled: checkButtonStatus(state),
      };
    case 'onChangeAds':
      let propName1 = action.payload.name;
      let propParent1 = action.payload.propParent;
      let selectedValue = action.payload.value;
      let finalObj1 = state['AdvancedSearch'];
      let basicObj = state['BasicSearch'];
      let AdSearchOptions = { ...state.AdvancedSearchOptions };
      if (propParent1) {
        finalObj1[propParent1][propName1] = action.payload.value;
        if (action.payload.doEmpty) {
          if (propParent1 === 'Health Status') {
            let emptyFieldName = Object.keys(finalObj1[propParent1])
              .filter((n) => n != propName1)
              .toString();
            finalObj1[propParent1][emptyFieldName] = '';
          } else {
            finalObj1[propParent1][action.payload.doEmpty] = '';
            if (propName1 === 'Operator') finalObj1[propParent1]['To'] = '';
          }
        }
      } else {
        let searchObj = AdvanceSearchCompontens.find((obj) => obj.name == propName1);
        if (searchObj) {
          if (searchObj['haveDependents']) {
            let fName = searchObj['haveDependents'];
            for (let i = 0; i < fName.length; i++) {
              let { dependentName, dependentValue } = fName[i];
              if (dependentValue == undefined || dependentValue == null) {
                finalObj1[dependentName] =
                  dependentName === 'Participation/Support Type Description' ? [] : '';
              } else if (
                action.payload.value.find(
                  (obj) => obj.name.toLowerCase() === dependentValue.toLowerCase()
                ) === undefined &&
                dependentName in finalObj1
              ) {
                finalObj1[dependentName] = 'Participation/Support Type Description' ? [] : '';
              }
            }
          }
        }
        if (propName1 === 'FO Child Status') {
          let rcdsStatus = [];
          try {
            let findName = (code) =>
              state['dropDownValue']['RCDSChildStatus'].find((elem) => elem.code == code);
            let getCode = (code) => {
              rcdsStatus.find((elem) => elem.code === code) ||
                rcdsStatus.push({ code, name: findName(code).name });
            };
            for (let i = 0; i < selectedValue.length; i++) {
              switch (parseInt(selectedValue[i].code)) {
                case 1:
                  getCode(1);
                  getCode(2);
                  getCode(3);
                  getCode(4);
                  break;
                case 2:
                  getCode(5);
                  getCode(6);
                  break;
                case 3:
                  getCode(7);
                  break;
                case 4:
                  getCode(8);
                  break;
                default:
                  rcdsStatus = [];
              }
            }
          } catch (err) {
            rcdsStatus = [];
          }
          basicObj['RCDSChildStatus'] = rcdsStatus;
          let deleteElem = document.getElementById('RCDSChildStatus');
          deleteElem &&
            (selectedValue.length === 0 ||
              selectedValue.length !== AdSearchOptions['FO Child Status'].length) &&
            deleteElem.getElementsByClassName('no-default')[0] &&
            deleteElem.getElementsByClassName('no-default')[0].remove();
        }
        finalObj1[propName1] = action.payload.value;
        if (propName1 === 'Participation/Support Type') {
          AdSearchOptions['Participation/Support Type Description'] = [];
        }
      }
      sessionStorage.setItem('vcAdvanceSearch', JSON.stringify(finalObj1));
      sessionStorage.setItem('csmAdvanceSearch', JSON.stringify(finalObj1));
      Object.assign(state, {
        BasicSearch: basicObj,
        AdvancedSearch: finalObj1,
        isButtonEnabled: checkButtonStatus(state),
        AdvancedSearchOptions: AdSearchOptions,
      });
      return {
        ...state,
      };
    case 'Registered_children_onClear':
      let adsState =
        state['BasicSearch']['RCDSChildStatus'].length !== 0
          ? state['AdvancedSearchOptions']['FO Child Status']
          : {};
      let adSearch = action.payload
        ? action.payload.pcrm
          ? {
              'PRCDS Child Status': { code: 2, name: 'Pledgeable' },
            }
          : {}
        : {};
      return {
        ...state,
        BasicSearch: {
          ChildCode: '',
          ChildGivenName: '',
          ChildFamilyName: '',
          ChildCountryId: [],
          AgeRange: {
            Operator: '',
            From: '',
            To: '',
          },
          Region: '',
          Gender: [],
          Project: [],
          RCDSChildStatus: [],
          ReservationID: '',
          ActionCode: [],
          RCDSAddDate: {
            Operator: '',
            From: '',
            To: '',
          },
          FieldOfficeLastUpdateDate: {
            Operator: '',
            From: '',
            To: '',
          },
        },
        AdvancedSearch: adSearch,
        showGrid: false,
        isButtonEnabled: action.payload ? (action.payload.pcrm ? true : false) : false,
        SelectedChildId: [],
        dropDownValue: {
          ...state.dropDownValue,
          ChildCountryId: [],
        },
        doPcrmReset: false,
      };
    case 'Add':
      let constructNewOne = action.payload.makeCopy
        ? { ...state['AdvancedSearch'], ...action.payload.adsObj }
        : action.payload.adsObj;
      return {
        ...state,
        AdvancedSearch: { ...constructNewOne },
      };
    case 'Set_Selected_Child':
      return {
        ...state,
        SelectedChildId: action.payload.childIds,
      };
    case 'Redirect':
      return {
        ...state,
        PageName: { from: state.PageName.to, to: action.payload.pageName },
      };
    case 'Search_other':
      Object.assign(state, {
        [action.payload.name]: { ...state[action.payload.name], ...action.payload.value },
      });
      return {
        ...state,
      };
    case 'Advanced_search_option':
      let allOptions = { ...state.AdvancedSearchOptions };
      allOptions[action.payload.name] = action.payload.value;
      return {
        ...state,
        AdvancedSearchOptions: allOptions,
      };
    case 'default_value_load':
      let bs = JSON.parse(JSON.stringify(state.BasicSearch));
      bs[action.payload.name] = action.payload.value;
      let dv = JSON.parse(JSON.stringify(state.dropDownValue));
      dv[action.payload.name] = action.payload.value;
      return {
        ...state,
        dropDownValue: dv,
        isButtonEnabled: checkButtonStatus(state),
      };
    case 'single_property_update':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
export default searchChildReducer;
