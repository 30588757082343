const initialStateVal = {
  userRoleData: [{
    GroupsAssigned:[]
  }],
  createUserProfileData: [],
  showCloseModelPopup: false,
  userRoleFields: {
    Email: false,
    FDRO: false,
    Region: false,
    Status: false,
    firstName: false,
    lastName: false,
    wvCentral: false,
  },
  isSaveDisabled: true,
  availableRolName:[]
};

const roleReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case 'userRoleDataAction':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'updateErrorMsg':
      return {
        ...state,
        [action.payload.errorName]: {
          ...state[action.payload.errorName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case 'createUserProfileDataAction':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'buttonDisableAction':
      return { ...state, isSaveDisabled: action.payload.value };
    default:
      return state;
  }
};
export default roleReducer;
