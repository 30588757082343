const initialState = {
  region: '',
  office: [],
  selectedRows: [],
  showGrid: false,
  checkedRows: [],
  selectedRole: '',
  roleScreenSelected: '',
  roleScreenCheckedRows: [],
  roleInput: '',
  availableRolName: [],
  roleDefaultValue: {
    accessTypes: {roleName:'',status:'Active'},
    checkedRows: [],
  },
  matrixTable: [],
};
const userAccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UserAccess_Onchange':
      let officeValue = state.office;
      if (action.payload.name === 'region') {
        officeValue = [];
      } else if (action.payload.name === 'office') {
        officeValue = action.payload.value;
      }
      return {
        ...state,
        [action.payload.name]: action.payload.value,
        office: officeValue,
      };
    case 'UserAccess_OnClear':
      return {
        ...state,
        region: '',
        office: [],
      };
    case 'UserAccess_Redirect':
      return {
        ...state,
        checkedRows: [],
        selectedRole: '',
      };
    case 'UserRole_Create_Reset':
      return {
        ...state,
        roleScreenSelected: '',
        roleScreenCheckedRows:
          action.payload.mode === 'create' ? [] : state.roleDefaultValue.checkedRows,
        roleInput: '',
      };
    case 'UserRole_Set_Route_Value':
      return {
        ...state,
        roleScreenSelected: action.payload.role,
        roleScreenCheckedRows: action.payload.checkedRows,
        roleInput: action.payload.roleInput,
      };
    case 'UserAccessOther':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'User_Role_Default_Change':
      return {
        ...state,
        [action.payload.parent]: {
          ...action.payload.parent,
          [action.payload.child]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
export default userAccessReducer;
