import React, { useEffect, useState, useCallback } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './wvSideBar.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import WvLanguage from '../../Common/Multilingual/WvLanguage';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import userAvatar from '../../../assets/images/Profile.svg';
const MenuComponent = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = WvLanguage();
  const userData = useSelector((state) => state.LoginReducer);
  const navigateTo = (url) => {
    history.push(url);
    props.openMenu();
  };
  const callingSearchNavigate = () => {
    sessionStorage.removeItem('searchChildResult');
    sessionStorage.removeItem('vcBasicSearch');
    sessionStorage.removeItem('vcAdvanceSearch');
    sessionStorage.removeItem('gridPageDetails');
    sessionStorage.removeItem('viewchild');
    sessionStorage.removeItem('csmSearchChildResult');
    sessionStorage.removeItem('csmBasicSearch');
    sessionStorage.removeItem('csmAdvanceSearch');
    sessionStorage.removeItem('selectedCSM');
    sessionStorage.removeItem('gridCSMPageDetails');
    sessionStorage.removeItem('csmBackButton');
    sessionStorage.removeItem('viewCSMBackButton');
    navigateTo('/searchchild');
  };
  const items = [
    {
      label: `${t('registered_children')}`,
      className: 'searchchild_parent',
      items: [
        {
          id: 'Registered Child',
          label: `${t('search_child')}`,
          className: 'searchchild_child',
          command: callingSearchNavigate,
        },
      ],
    },
    {
      label: `${t('projects')}`,
      className: 'searchproject_parent',
      items: [
        {
          id: 'Projects',
          label: `${t('search_project')}`,
          className: 'searchproject_child',
          command: () => navigateTo('/searchproject'),
        },
      ],
    },
    {
      label: `${t('reservations')}`,
      className: 'reservation_parent',
      items: [
        {
          id: 'Reservation',
          label: `${t('create_reservation_pool')}`,
          className: 'create_child',
          command: () => navigateTo('/reservation/create'),
        },
        {
          id: 'Reservation',
          label: `${t('manage_reservation')}`,
          className: 'manage_child',
          command: () => navigateTo('/reservation/manage'),
        },
        {
          id: 'Reservation',
          label: `${t('batch_import')}`,
          className: 'import_child',
          command: () => navigateTo('/reservation/import'),
        },
      ],
    },
    {
      label: `${t('administration')}`,
      className: 'administration_parent',
      items: [
        {
          id: 'Admin Office Setting',
          label: `${t('office_settings')}`,
          className: 'FundRaisingOfficeSetting_child',
          command: () => navigateTo('/administration/FundRaisingOfficeSetting'),
        },
        {
          id: 'Admin User Profile Management',
          label: `${t('user_profile_management')}`,
          className: 'userprofile_child',
          command: () => navigateTo('/administration/userprofile'),
        },
        {
          id: 'Admin Role Management',
          label: `${t('user_role_management')}`,
          className: 'userrole_child',
          command: () => navigateTo('/administration/userrole'),
        },
        {
          id: 'Admin User Group Management',
          label: `${t('user_group')}`,
          className: 'userGroup_child',
          command: () => navigateTo('/administration/userGroup'),
        },
      ],
    },
  ];
  const [MenuOptions, SetMenuOption] = useState([...items]);
  const action = useDispatch();
  useEffect(() => {
    try {
      var loc = location.pathname;
      loc = loc.split('/');
      document
        .getElementsByClassName(loc[1] + '_parent')[0]
        .getElementsByTagName('a')[0]
        .click();
      setTimeout(() => {
        try {
          if (
            document.getElementsByClassName(loc[1] + '_parent')[0].getElementsByTagName('li')
              .length === 1
          ) {
            document
              .getElementsByClassName(loc[1] + '_child')[0]
              .children[0].classList.add('active-li');
          } else {
            document
              .getElementsByClassName(loc[2] + '_child')[0]
              .children[0].classList.add('active-li');
          }
        } catch (e) {}
      }, 800);
    } catch (msg) {}
  }, [MenuOptions]);
  useEffect(() => {
    let dynamicItems = [];
    for (let i = 0; i < items.length; i++) {
      let childRow = [];
      for (let k = 0; k < items[i].items.length; k++) {
        let finder = userData.userRoleDetail.find((ele) => ele.ModuleName === items[i].items[k].id);
        if (items[i].items.length === 1) {
          finder.enableModule && dynamicItems.push(items[i]);
        } else if (items[i].items[k].id === 'Reservation') {
          (items[i].items[k].label === 'Create Reservation' ||
            items[i].items[k].label === 'Batch Import') &&
            finder.Access.Create &&
            childRow.push(items[i].items[k]);
          items[i].items[k].label === 'Manage Reservation' &&
            finder.Access.View &&
            childRow.push(items[i].items[k]);
        } else {
          finder.enableModule && childRow.push(items[i].items[k]);
        }
      }
      if (items[i].items.length >= 2 && childRow.length !== 0) {
        dynamicItems.push({
          label: items[i].label,
          className: items[i].className,
          items: childRow,
        });
      }
    }
    SetMenuOption(dynamicItems);
  }, []);
  const handleClose = (e) => {
    props.openMenu();
  };
  function getUserName() {
    if (userData.username.length > 0) {
      return userData.username;
    }
  }
  const getCookieMemo = useCallback(() => getCookie(), []);
  function getCookie() {
    let name = 'username' + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  const decodeUserName = localStorage.getItem('profile')
    ? JSON.parse(localStorage.getItem('profile'))
    : 'UserName';
  const populateUsername = decodeUserName === 'UserName' ? decodeUserName : decodeUserName.name;
  const preferredUsername =
    decodeUserName.preferred_username !== undefined ? decodeUserName.preferred_username : 'Guest';

  return (
    <div>
      <div className="menuwrapper">
        <div className="p-grid">
          <div>
            <Offcanvas
              show={true}
              onHide={handleClose}
              id="mv-drawer"
              className="p-col-8 p-sm-4 p-md-5 p-lg-3 p-xl-2"
            >
              <Offcanvas.Body className="p-p-0">
                <Link
                  to={{
                    pathname: '/administration/userProfileView',
                    search: `?id=${userData.userDetail.UserId}&mode=view`,
                  }}
                  onClick={() => {
                    sessionStorage.setItem('backUrl', true);
                    handleClose();
                  }}
                >
                  <div className="p-grid p-justify-center p-align-end p-pl-2 p-pr-2 p-pt-5 p-mb-2">
                    <div className="p-col-3">
                      <span className="profile-icon position-relative">
                        {' '}
                        <img alt="appName" src={userAvatar} />
                      </span>
                    </div>
                    <div className="p-col-9">
                      <div className="p-mr-2 p-d-flex" style={{ flexDirection: 'column' }}>
                        <label className="userName user mb-0 p-mt-2" style={{ cursor: 'pointer' }}>
                          {populateUsername}
                        </label>
                        <label className="country" style={{ cursor: 'pointer' }}>
                          {t('logged_in_as')}
                          &nbsp;
                          <strong>{preferredUsername}</strong>
                        </label>
                      </div>
                    </div>
                  </div>
                </Link>
                <PanelMenu model={MenuOptions} className="wv-menuPanel" />
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>
      </div>
    </div>
  );
};
MenuComponent.propTypes = {
  openMenu: PropTypes.any,
};
export default MenuComponent;
