const initialState = {
  importTypeOptions: '',
  importType: '',
  sampleFileInput: '',
  authorName: '',
  validateExcelDataButton: [{}],
  showRCDSGrid: false,
  showCSMGrid: false,
  basicSearch: {
    CreatedBy: [],
    ImportStatus: [],
    ImportDate: '',
  },
  dropDownValue: {
    CreatedBy: [],
    ImportStatus: [],
  },
};
const importDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'captureDropdownImportOptions':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'captureFileName':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'resetImportDataFields':
      return {
        ...state,
        importType: '',
        sampleFileInput: '',
        validateExcelDataButton: [{}],
      };
    case 'captureExcelColumnData':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'showRCDSGrid':
      return {
        ...state,
        showRCDSGrid: action.payload.setShowRCDSGrid,
      };
    case 'showCSMGrid':
      return {
        ...state,
        showCSMGrid: action.payload.setShowCSMGrid,
      };
    case 'import_reservations_others':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'import_search_onChange':
      let { name, value, doEmpty } = action.payload;
      let copyOby = { ...state.basicSearch };
      copyOby[name] = value;
      let dropDownOption = state.dropDownValue;
      return {
        ...state,
        basicSearch: copyOby,
      };
    case 'import_search_onClear':
      return {
        ...state,
        basicSearch: {
          CreatedBy: [],
          ImportStatus: [],
          ImportDate: '',
        },
      };
    case 'importUpdateOnLoad':
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    default:
      return state;
  }
};
export default importDataReducer;
