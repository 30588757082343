import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import * as base64 from 'base64-js';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import { ThreeDots } from 'react-loader-spinner';
import AccessDeniedPage from './AccessDeniedPage';
import LandingScreen from '../component/Module/SSO/UI/LandingPage';
import { BlockUI } from 'primereact/blockui';
import { useDispatch } from 'react-redux';
const OidcAuthComponent = ({ location }) => {
  const query = new URLSearchParams(location.search);
  const Location = useLocation();
  const Dispatch = useDispatch();
  const [authState, setAuthState] = useState({
    authCode: query.get('code'),
    errorMessage: query.get('error_description'),
    authURL: '',
    user: {},
  });
  const history = useHistory();

  useEffect(() => {
    if (authState.authURL == '' && !authState.authCode) {
      PKCEAuthCodeFirstStep()
        .then((codeChallenge) => {
          let oidcURL = `${process.env.REACT_APP_IDP_URL}/auth`;
          let queryParams = [`client_id=${process.env.REACT_APP_CLIENT_ID}`];
          queryParams.push(`code_challenge=${codeChallenge}`);
          queryParams.push(process.env.REACT_APP_REDIRECT_URI_QUERY);
          queryParams.push(`code_challenge_method=S256`);
          queryParams.push(`response_type=code`);
          queryParams.push(`scope=openid params`);
          setAuthState({ ...authState, authURL: `${oidcURL}?${queryParams.join('&')}` });
          if (
            localStorage.getItem('oneLoginDetails') === null ||
            localStorage.getItem('oneLoginDetails') === undefined
          ) {
            if (Location.state && Location.state.redirect) {
              sessionStorage.setItem(
                'isRedirect',
                JSON.stringify({
                  flag: true,
                  pathname: Location.state.pathname,
                  search: Location.state.search,
                })
              );
            }
            query.get('error')
              ? history.push('/accessDeniedError')
              : window.location.assign(`${oidcURL}?${queryParams.join('&')}`);
          }
        })
        .catch((e) => {
          localStorage.clear();
        });
    }
    if (
      authState.authCode &&
      localStorage.getItem('oneLoginDetails') === null &&
      localStorage.getItem('getRefreshToken') !== 'no'
    ) {
      PKCEAuthCodeSecondStep(authState.authCode)
        .then((res) => {
          const data = JSON.stringify(res.data);
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('AuthCode', authState.authCode);
          localStorage.setItem('oneLoginDetails', data);
          let userProfile = jwtDecode(res.data.id_token);
          localStorage.setItem('profile', JSON.stringify(userProfile));
          sessionStorage.setItem('UserLogged', userProfile.email);
          Dispatch({
            type: 'Common_Others',
            payload: {
              name: 'userLoggedIn',
              value: true,
            },
          });
          Dispatch({
            type: 'Common_Others',
            payload: {
              name: 'lastRefeshAt',
              value: new Date().getTime(),
            },
          });
          getUserInfo()
            .then((user) => {
              setAuthState({ ...authState, user });
              if (localStorage.getItem('userUrl') !== null) {
                const url = JSON.parse(localStorage.getItem('userUrl'));
                history.push({ pathname: url.pathname, search: url.search });
              } else {
                if (sessionStorage.getItem('isRedirect')) {
                  let sessionData = JSON.parse(sessionStorage.getItem('isRedirect'));
                  history.push({
                    pathname: sessionData.pathname,
                    search: sessionData.search,
                  });
                  sessionStorage.removeItem('isRedirect');
                } else {
                  history.push('/');
                }
              }
            })
            .catch(() => {
              localStorage.clear();
            });
        })
        .catch((e) => {});
    } else if (
      authState.authCode &&
      localStorage.getItem('oneLoginDetails') === null &&
      localStorage.getItem('getRefreshToken') === 'no'
    ) {
      PKCEAuthCodeSecondStep(authState.authCode)
        .then((res) => {
          const data = JSON.stringify(res.data);
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('AuthCode', authState.authCode);
          localStorage.setItem('oneLoginDetails', data);
          let userProfile = jwtDecode(res.data.id_token);
          localStorage.setItem('profile', JSON.stringify(userProfile));
          sessionStorage.setItem('UserLogged', userProfile.email);
          Dispatch({
            type: 'Common_Others',
            payload: {
              name: 'userLoggedIn',
              value: true,
            },
          });
          Dispatch({
            type: 'Common_Others',
            payload: {
              name: 'lastRefeshAt',
              value: new Date().getTime(),
            },
          });
          getUserInfo().then((user) => {
            setAuthState({ ...authState, user });
            if (localStorage.getItem('userUrl') !== null) {
              const url = JSON.parse(localStorage.getItem('userUrl'));
              history.push({ pathname: url.pathname, search: url.search });
            } else {
              if (sessionStorage.getItem('isRedirect') === 'true') {
                let sessionData = JSON.parse(sessionStorage.getItem('isRedirect'));
                history.push({
                  pathname: sessionData.pathname,
                  search: sessionData.search,
                });
                sessionStorage.removeItem('isRedirect');
              } else {
                history.push('/');
              }
            }
          });
        })
        .catch((e) => {});
    }
  }, []);

  return (
    <>
      <BlockUI
        blocked={true}
        template={
          <>
            <ThreeDots color="#FF6B00" height={80} width={80} ariaLabel="Loading" />
          </>
        }
        fullScreen
      ></BlockUI>
    </>
  );
};
const PKCEAuthCodeFirstStep = async () => {
  let codeVerifier = createCodeVerifier(50);
  localStorage.setItem('code_verifier', codeVerifier);
  return await createCodeChallenge(codeVerifier);
};

const PKCEAuthCodeSecondStep = async (code) => {
  let oidcURL = `${process.env.REACT_APP_IDP_URL}/token`;

  let params = stringify({
    grant_type: 'authorization_code',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    client_id: process.env.REACT_APP_CLIENT_ID,
    code_verifier: localStorage.getItem('code_verifier'),
    code,
  });
  localStorage.setItem('getRefreshToken', 'no');

  return await axios.post(oidcURL, params, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });
};

const getUserInfo = async () => {
  let userInfoURL = `${process.env.REACT_APP_IDP_URL}/me`;
  let token = localStorage.getItem('access_token');
  return await axios.get(userInfoURL, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

const createCodeVerifier = (size) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
  const charsetIndexBuffer = new Uint8Array(size);

  for (let i = 0; i < size; i += 1) {
    charsetIndexBuffer[i] = (Math.random() * charset.length) | 0;
  }

  let randomChars = [];
  for (let i = 0; i < charsetIndexBuffer.byteLength; i += 1) {
    let index = charsetIndexBuffer[i] % charset.length;
    randomChars.push(charset[index]);
  }

  return randomChars.join('');
};

const createCodeChallenge = (codeVerifier) => {
  if (typeof window !== 'undefined' && !!window.crypto && !!window.crypto.subtle) {
    return new Promise((resolve, reject) => {
      let codeVerifierCharCodes = textEncodeLite(codeVerifier);
      crypto.subtle.digest('SHA-256', codeVerifierCharCodes).then(
        (hashedCharCodes) => resolve(urlSafe(new Uint8Array(hashedCharCodes))),
        (error) => reject(error)
      );
    });
  }
};

const textEncodeLite = (str) => {
  const charCodeBuffer = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    charCodeBuffer[i] = str.charCodeAt(i);
  }
  return charCodeBuffer;
};

const urlSafe = (buffer) => {
  const encoded = base64.fromByteArray(new Uint8Array(buffer));
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};
OidcAuthComponent.propTypes = {
  location: PropTypes.object,
};
export default OidcAuthComponent;
