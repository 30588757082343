import { useTranslation } from 'react-i18next';

function WvLanguage() {
  const { t, i18n } = useTranslation();
  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }
  return { t, changeLanguage };
}
export default WvLanguage;
