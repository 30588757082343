import MenuComponent from '../Menus/wvSideBar';
import { useMemo, useRef, useState, useEffect, useCallback } from 'react';

import { SpeedDial } from 'primereact/speeddial';
import './wvHeader.css';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import WvLanguage from '../Multilingual/WvLanguage';
import { ProfileService } from '../../Module/SSO/utils/Service';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/prcds-leaf.png';
import menuIcon from '../../../assets/images/menuicon.svg';
import envisionLogo from '../../../assets/images/wv-logo-transparent.png';
const Header = () => {
  let [showMenu, setShowMenu] = useState(true);
  const toast = useRef();
  const location = useLocation();
  const ProfileReduxData = useSelector((state) => state.LoginReducer);
  const action = useDispatch();
  const noShowUrl = ['/accessDeniedError', '/unauthorized'];
  const formURL = (url) => {
    if (url && url.length !== 0) {
      location.search = url;
    } else {
      url = location.pathname.substring(location.pathname.lastIndexOf('?'));
      location.search = url;
    }
    return url;
  };
  const getParams = useMemo(() => new URLSearchParams(formURL(location.search)), [location.search]);
  const [selectedCountries, setSelectedCountries] = useState('India');
  const history = useHistory();
  const { t } = WvLanguage();

  const op = useRef(null);
  const listRef = useRef(null);
  const onMenuIconClick = () => {
    setShowMenu(!showMenu);
  };

  const items = [
    {
      label: 'Add',
      icon: 'pi pi-pencil',
      command: () => {
        history.push('/');
      },
    },
    {
      label: 'Update',
      icon: 'pi pi-refresh',
      command: () => {
        history.push('/help');
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        openConfirmLogout();
      },
    },
  ];
  const logOut = useCallback(() => {
    var allCookies = document.cookie.split(';');
    localStorage.removeItem('tokenInformation');
    window.localStorage.clear();
    window.location.assign(`${process.env.REACT_APP_IDP_URL}/logout`);
  }, []);
  const openConfirmLogout = () => {
    confirmDialog({
      message: t('logout_title'),
      header: t('confirm_logout'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: t('yes_button'),
      className: 'mobile-wid header-logout',
      rejectLabel: t('no_button'),
      accept: () => logOut(),
    });
  };
  useEffect(() => {
    function getCookie(name) {
      var cname = name + '=';
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(cname) == 0) {
          let cookieData = c.substring(cname.length, c.length);
          try {
            let userProfile = localStorage.getItem('profile');
            userProfile = JSON.parse(userProfile);
            cookieData = JSON.parse(cookieData);
            if (userProfile.email === cookieData.user) {
              return c.substring(cname.length, c.length);
            }
            return '';
          } catch (err) {
            return '';
          }
        }
      }
      return '';
    }
    try {
      getCookie('PRCDSLegalConsent').length === 0 && setShowMenu(false);
    } catch (err) {}
  });
  const accessError =
    getParams.get('error') !== 'access_denied' &&
    getParams.get('error_description') !== 'End-user does not have access to this application';
  const pcrmFalseCase =
    !getParams.get('type') ||
    (getParams.get('type') && getParams.get('type').toLowerCase() !== 'nonav');
  useEffect(() => {
    try {
      function handleContextMenu(e) {
        if (pcrmFalseCase === false) {
          e.preventDefault();
        }
      }
      document.addEventListener('contextmenu', handleContextMenu);
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    if (Object.values(ProfileReduxData.userDetail).filter((ele) => Boolean(ele)).length === 1) {
      ProfileService.getProfileDetail()
        .then((res) => {
          action({
            type: 'Update_User_Detail',
            payload: {
              value: res.data.User,
            },
          });
        })
        .catch(() => {
          console.log('Unable to fetch user info');
        });
    }
  }, []);
  return (
    <>
      {noShowUrl.includes(location.pathname) === false &&
      pcrmFalseCase &&
      location.pathname === '/help' ? (
        <div
          className="p-grid wv-header"
          style={{ alignItems: 'center', paddingLeft: '3%', paddingRight: '3%' }}
        >
          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 p-pl-0 wv-grow">
            <img
              id="header_img3"
              alt="logo"
              // src={require('../../../assets/images/prcds-leaf.png').default}
              src={logo}
              onClick={() => history.push('/')}
            />
          </div>
          <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
            <img id="wv-logo" alt="WV Logo" src={envisionLogo} style={{ float: 'right' }} />
          </div>
        </div>
      ) : (
        noShowUrl.includes(location.pathname) === false &&
        pcrmFalseCase && (
          <div className="p-grid wv-header" style={{ alignItems: 'center' }}>
            <div className="p-col-1 p-md-1 p-lg-1 p-xl-1 menu-btn-div">
              <button id="header_btn1" onClick={onMenuIconClick}>
                <img id="header_img1" alt="" src={menuIcon} />
              </button>
            </div>
            <div className="p-col-1 p-md-4 p-lg-4 p-xl-4 p-pl-0 wv-grow">
              <img id="header_img3" alt="logo" src={logo} onClick={() => history.push('/')} />
            </div>
            <div className="p-col-4 p-md-2 p-lg-2 p-xl-2 col-sm-header">
              <SpeedDial
                model={items}
                radius={80}
                direction="down"
                showIcon="pi pi-ellipsis-v"
                rotateAnimation={false}
                type="type"
                buttonClassName="p-button-rounded p-button-text p-button-plain"
              />
            </div>
            <div className="p-col-4 p-md-2 p-lg-2 p-xl-1 icons-div">
              <div className="p-d-flex p-jc-center nav-border p-mt-2">
                <div className="p-mr-2">
                  <div
                    id="home_bg"
                    className="header-icons"
                    onClick={() => history.push('/')}
                  ></div>
                </div>
                <div className="p-mr-2">
                  <div
                    id="help_bg"
                    className="header-icons"
                    onClick={() => history.push('/help')}
                  ></div>
                </div>
                <div className="p-mr-2">
                  <div
                    id="logout-bg"
                    className="header-icons"
                    onClick={() => openConfirmLogout()}
                  ></div>
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-1 p-lg-1 p-xl-1 logo-div">
              <img id="wv-logo" alt="" src={envisionLogo} />
            </div>
          </div>
        )
      )}

      {noShowUrl.includes(location.pathname) === false && pcrmFalseCase && showMenu && (
        <MenuComponent openMenu={onMenuIconClick} />
      )}
      <Toast ref={toast} position="bottom-left" />
    </>
  );
};

export default Header;
