import JWTInterceptor from '../../../../Interceptors/JWTInterceptor';
const FDROService = {
  getFdroGroupTabsData: (api) => {
    return JWTInterceptor.get(`${process.env.REACT_APP_API_BASE_URL}${api}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  getCountryListForCountryGroup: () => {
    return JWTInterceptor.get(`${process.env.REACT_APP_API_BASE_URL}country`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  deleteCountryListForCountryGroup: (CountryGroupId, fdro) => {
    return JWTInterceptor.delete(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/countryGroup/${CountryGroupId}`
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  putUpdateSaveCountryGroup: (modifiedObjects, fdro) => {
    return JWTInterceptor.put(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/countryGroup`,
      modifiedObjects
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  postSingleUpdateRC: (stNum, singleRCObj, fdro = 'ESPO') => {
    return JWTInterceptor.post(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/category/2`,
      {
        params: {
          settingType: stNum,
        },
      },
      singleRCObj,
      { headers: { 'Content-Type': 'application/json' } }
    );
  },
  postBulkUpdateRC: (modifiedRCObj, fdro) => {
    return JWTInterceptor.post(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/category/2/bulk`,
      modifiedRCObj
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  postBulkUpdateSC: (modifiedSCObj, fdro) => {
    return JWTInterceptor.post(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/category/3/bulk`,
      modifiedSCObj
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getGeneralDropdown: (category, settingType, fdro) => {
    return JWTInterceptor.get(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/listPredefinedOptions?category=${category}&settingType=${settingType}`
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  getGeneralUnitOfMeasure: (category, settingType, fdro) => {
    return JWTInterceptor.get(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/unitOfMeasure?categoryCode=${category}&settingTypeCode=${settingType}`
    )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  },
  postBulkUpdateEveryTab: (categoryCode, modifiedData, fdro) => {
    return JWTInterceptor.post(
      `${process.env.REACT_APP_API_BASE_URL}fundraisingOfficeSettings/${fdro}/category/${categoryCode}/bulk`,
      modifiedData
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getSponsorType: async (fdro) => {
    let result = await JWTInterceptor.get(`fundraisingOfficeSettings/${fdro}/sponsorType`);
    return result;
  },
  saveSponsorType: async (body, fdro) => {
    let result = await JWTInterceptor.put(`fundraisingOfficeSettings/${fdro}/sponsorType`, body);
    return result;
  },
  UpdateReservation: async (body, fdro) => {
    let result = await JWTInterceptor.post(
      `fundraisingOfficeSettings/${fdro}/category/5/bulk`,
      body
    );
    return result;
  },
  updateRatingTypeProject: async (body, fdro) => {
    let result = await JWTInterceptor.put(
      `fundraisingOfficeSettings/${fdro}/projectCategory/${body.ProjectCategory.ProjectCategoryId}/ratingLevel`,
      body
    );
    return result;
  },
  createProjectCategory: async (body, fdro, method = 'create') => {
    let result =
      method === 'create'
        ? await JWTInterceptor.post(`fundraisingOfficeSettings/${fdro}/projectCategory`, body)
        : await JWTInterceptor.put(`fundraisingOfficeSettings/${fdro}/projectCategory`, body);
    return result;
  },
  deleteProjectCategory: async (id, fdro) => {
    let result = await JWTInterceptor.delete(
      `fundraisingOfficeSettings/${fdro}/projectCategory/${id}`
    );
    return result;
  },
  getOfficeSettingType: async (id) => {
    let result = await JWTInterceptor.get(`list/officeSettingType?Category_Code=${id}`);
    return result;
  },
  getAllCategory: async () => {
    let result = await JWTInterceptor.get('list/officeSettingTypeCategory');
    return result;
  },
  getRecipientDetails: async (fdro, notificationId) => {
    let result = await JWTInterceptor.get(
      `fundraisingOfficeSettings/${fdro}/notifications/${notificationId}/recipients`
    );
    return result;
  },
  getAllUsers: async (fdro) => {
    let result = await JWTInterceptor.get(`users/${fdro}?Status=Active`);
    return result;
  },
  getTemplate: async (fdro, id) => {
    let result = await JWTInterceptor.get(
      `fundraisingOfficeSettings/${fdro}/notifications/${id}/template`
    );
    return result;
  },
  updateRecepiantDeatils: async (fdro, id, response) => {
    let result = await JWTInterceptor.put(
      `fundraisingOfficeSettings/${fdro}/notifications/${id}/recipients`,
      response
    );
    return result;
  },
  updateNotificationDeatils: async (fdro, response) => {
    let result = await JWTInterceptor.put(
      `fundraisingOfficeSettings/${fdro}/notifications`,
      response
    );
    return result;
  },
  fileUpload: async (data, fdro) => {
    let result = JWTInterceptor.post(`fundraisingOfficeSettings/${fdro}/policyDocument`, data);
    return result;
  },
  getPolicyStatementPdf: async (FDRO) => {
    let result = await JWTInterceptor.get(`fundraisingOfficeSettings/${FDRO}/policyDocument`);
    return result;
  },
};
export default FDROService;
