import React, { useEffect, useMemo } from 'react';
import WvLanguage from '../component/Common/Multilingual/WvLanguage';
import './AccessDeniedPage.css';
import AccessDeniedImage from '../assets/images/accessDenied.png';
import { useHistory, useLocation } from 'react-router-dom';
const UnauthorizedScreen = () => {
  const { t, changeLanguage } = WvLanguage();
  const history = useHistory();
  const location = useLocation();
  const getParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  let isPcrm = getParams.get('type') === 'nonav' ? true : false;
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (localStorage.getItem('oneLoginDetails')) {
      history.push('/');
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className="adDiv">
      <img src={AccessDeniedImage} alt="accessdenied" className="adImage" />
      <h2 className="pcrm_heading">{t('unauthorized_access_heading')}</h2>
      {isPcrm ? (
        <p className="pcrmErrorMessage">{t('unauthorized_access_pcrm_msg')}</p>
      ) : (
        <>
          <p className="pcrmErrorMessage">{t('unauthorized_access_msg')}</p>
          <a href={`${process.env.REACT_APP_IDP_URL}/logout`}> Log in</a>
        </>
      )}
    </div>
  );
};
export default UnauthorizedScreen;
