const initialState = {
  AllData: {},
  isbtnEnabled: false,
};
function checkEnabled(all) {
  var result = false;
  for (let k = 0; k < all.length; k++) {
    var allRow = all[k].codes.filter((ele) => ele.selected);
    if (allRow.length != 0) {
      result = true;
      break;
    }
  }
  return result;
}
export const sendCsmReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'Add_Data':
      return {
        ...state,
        AllData: action.payload.data,
        isbtnEnabled: checkEnabled(action.payload.data),
      };
    case 'Change_Data':
      let all = state.AllData;
      let actionRows = action.payload.rows;
      let findedOne = all.findIndex((obj) => obj.ChildId === action.payload.parent);
      findedOne = all[findedOne].codes;
      for (let i = 0; i < findedOne.length; i++) {
        if (actionRows.some((obj) => obj.code == findedOne[i].code)) {
          findedOne[i].selected = true;
        } else {
          findedOne[i].selected = false;
        }
      }
      return {
        ...state,
        AllData: all,
        isbtnEnabled: checkEnabled(all),
      };
    default:
      return state;
  }
};
