import React, { lazy, Suspense } from 'react';
import '../App.css';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { ThreeDots } from 'react-loader-spinner';
import Header from '../component/Common/Header/wvHeader';
import UserAccessManagement from '../component/Module/UserAccess/UI/UserAccessComponent';
import ProtectedRoute from './ProtectedRoute';

const OidcAuthComponent = lazy(() => import('../Odic/OidcAuthComponent'));
const UserDate = lazy(() => import('../component/Module/Date/UI/UserDate'));
const HelpScreenComponent = lazy(() =>
  import('../component/Common/HelpScreen/HelpScreenComponent')
);
const AccessDeniedPage = lazy(() => import('../Odic/AccessDeniedPage'));
const UnauthorizedScreen = lazy(() => import('../Odic/UnauthorizedPage'));
const ViewChildComponent = lazy(() =>
  import('../component/Module/Viewchild/UI/ViewChildComponent')
);
const ViewProfileComponent = lazy(() =>
  import('../component/Module/UserRole/UI/ViewProfileComponent')
);
const SearchRC = lazy(() => import('../component/Module/Searchchild/UI/SearchChildComponent'));
const CreateReservation = lazy(() =>
  import('../component/Module/CreateReservation/UI/CreateReservationComponent')
);
const ManageReservation = lazy(() =>
  import('../component/Module/ManageReservation/UI/ManageReservationComponent')
);
const UserRole = lazy(() => import('../component/Module/UserRole/UI/UserRoleComponent'));
const UserGroupMainPage = lazy(() => import('../component/Module/UserGroup/UI/UserGroupMainPage'));
const ViewUserGroup = lazy(() => import('../component/Module/UserGroup/UI/ViewUserGroup'));
const CreateUserGroup = lazy(() => import('../component/Module/UserGroup/UI/CreateUserGroup'));
const EditUserGroup = lazy(() => import('../component/Module/UserGroup/UI/EditUserGroup'));
const UserProfileCreate = lazy(() => import('../component/Module/UserRole/UI/UserProfileCreate'));
const UserProfile = lazy(() => import('../component/Module/UserRole/UI/UserProfile'));
const DashboardScreen = lazy(() => import('../component/Module/Dashboard/mvDashboardScreen'));
const Setting = lazy(() => import('../component/Module/Setting/UI/SettingComponent'));
const LoginPage = lazy(() => import('../component/Module/SSO/UI/LoginPageComponent'));
const ProjectPage = lazy(() => import('../component/Module/Project/UI/ProjectMainComponent'));
const LandingScreen = lazy(() => import('../component/Module/SSO/UI/LandingPage'));

const ViewProjectComponent = lazy(() =>
  import('../component/Module/Project/UI/ViewProjectParentTab')
);
const APIConfigSetSearchComp = lazy(() =>
  import('../component/Module/Admin/UI/APIConfigSetSearchComp')
);
const SendCSM = lazy(() => import('../component/Module/SendCsm/UI/CsmMainComponent'));
const ViewCsmComponent = lazy(() => import('../component/Module/SendCsm/UI/ViewCsmComponent'));
const AssignRole = lazy(() => import('../component/Module/UserAccess/UI/AssignRole'));
const UserRoleCVE = lazy(() => import('../component/Module/UserRole/UI/UserRoleCVE'));
const ReservationViewEdit = lazy(() =>
  import('../component/Module/ManageReservation/UI/ReservationViewEdit')
);
const SupportOfficeSetting = lazy(() =>
  import('../component/Module/Admin/UI/SupportOfficeSetting')
);
const SupportOfficeAuditLogHistory = lazy(() =>
  import('../component/Module/AuditLogHistoryTracking/UI/SupportOfficeAuditLogHistory')
);
const ImportDataComponent = lazy(() =>
  import('../component/Module/ImportData/Ui/ImportDataComponent')
);
const IDRCDSGridComponent = lazy(() =>
  import('../component/Module/ImportData/Ui/IDRCDSGridComponent')
);
const ReservationSearchChild = lazy(() =>
  import('../component/Module/CreateReservation/UI/ReservationSearchChild')
);
const ViewImportHistoryComp = lazy(() =>
  import('../component/Module/ImportData/Ui/ViewImportHistoryComp')
);
const APIViewEditComponent = lazy(() =>
  import('../component/Module/Admin/UI/APIViewEditComponent')
);
const pcrmResponseNavigation = lazy(() =>
  import('../component/Module/Pcrm/UI/SearchPcrmNavigation')
);
const pcrmNavigationFailScreen = lazy(() =>
  import('../component/Module/Pcrm/UI/PcrmNavigationFailScreen')
);
const ViewFundRaisingOfficeSetting = lazy(() =>
  import('../component/Module/FundRaisingOfficeSetting/UI/ViewFundRaisingOfficeSetting')
);
const ReservationAuditLog = lazy(() =>
  import('../component/Module/ManageReservation/UI/ReservationAuditLog')
);
const RegisterChildAuditLog = lazy(() =>
  import('../component/Module/Viewchild/UI/RegisterChildAuditLog')
);

const CSMAuditHistory = lazy(() => import('../component/Module/Viewchild/UI/CSMAuditHistory'));

const ViewActionCodeHistory = lazy(() =>
  import('../component/Module/Viewchild/UI/ViewActionCodeHistory')
);
const ViewProjectAuditLog = lazy(() => import('../component/Module/Project/UI/AuditHistory'));
const AppRouting = () => {
  const location = useLocation();
  const action = useDispatch();
  if (
    (sessionStorage.getItem('location') === null ||
      sessionStorage.getItem('location') !== 'viewChild') &&
    location.pathname !== '/searchchild' &&
    (!sessionStorage.getItem('viewchild') || location.pathname !== '/searchchild/viewchild')
  ) {
    if (location.pathname !== '/searchchild/viewSendCsm') {
      if (location.pathname !== '/searchproject/viewproject') {
        if (location.pathname !== '/searchproject/viewproject/auditHistory') {
          if (location.pathname !== '/searchchild/sendcsm') {
            if (location.pathname !== '/searchchild/viewchild/viewchildaudit')
              if (location.pathname !== '/searchchild/viewchild/viewCSMHistory') {
                if (location.pathname !== '/searchchild/viewchild/viewActionCodeHistory') {
                  if (location.pathname !== '/administration/userProfileView') {
                    {
                      sessionStorage.removeItem('searchChildResult');
                      sessionStorage.removeItem('vcBasicSearch');
                      sessionStorage.removeItem('childId');
                      sessionStorage.removeItem('vcAdvanceSearch');
                      sessionStorage.removeItem('gridPageDetails');
                      sessionStorage.removeItem('viewchild');
                    }
                  }
                }
              }
          }
        }
      }
    }
    if (location.pathname !== '/searchchild/sendcsm') {
      if (location.pathname !== '/searchchild/viewSendCsm') {
        if (location.pathname !== '/searchproject/viewproject') {
          if (location.pathname !== '/searchproject') {
            action({ type: 'Registered_children_onClear' });
          }
          action({ type: 'Set_Selected_Child', payload: { childIds: [] } });
        }
      }
    }
  }
  if (
    (sessionStorage.getItem('csmLocation') === null ||
      sessionStorage.getItem('csmLocation') !== 'sendCsmScreenLoc') &&
    location.pathname !== '/searchchild' &&
    (!sessionStorage.getItem('csmBackButton') || location.pathname !== '/searchchild/sendcsm')
  ) {
    if (location.pathname !== '/searchchild/viewchild') {
      if (location.pathname !== '/searchchild/viewchild/viewchildaudit') {
        if (location.pathname !== '/searchchild/viewchild/viewCSMHistory') {
          if (location.pathname !== '/searchchild/viewchild/viewActionCodeHistory') {
            if (location.pathname !== '/administration/userProfileView') {
              sessionStorage.removeItem('csmSearchChildResult');
              sessionStorage.removeItem('csmBasicSearch');
              sessionStorage.removeItem('csmAdvanceSearch');
              sessionStorage.removeItem('gridCSMPageDetails');
              sessionStorage.removeItem('csmBackButton');
              if (location.pathname !== '/searchchild/viewSendCsm') {
                sessionStorage.removeItem('fetchViewCSMData');
                if (location.pathname !== '/searchproject/viewproject') {
                  sessionStorage.removeItem('selectedCSM');
                  action({ type: 'Set_Selected_Child', payload: { childIds: [] } });
                  action({
                    type: 'buttonDisableAction',
                    payload: {
                      name: 'isSaveDisabled',
                      value: true,
                    },
                  });
                  action({
                    type: 'userRoleDataAction',
                    payload: {
                      name: 'userRoleData',
                      value: [],
                    },
                  });
                }
              }
            }
          }
        }
      }
    }
  }
  if (sessionStorage.getItem('importSearch')) {
    if (location.pathname !== '/reservation/viewImportHistory') {
      if (location.pathname !== '/reservation/viewImportHistory/viewImportDetails') {
        sessionStorage.removeItem('importSearch');
        sessionStorage.removeItem('importSearchValue');
      }
    }
  }

  if (location.pathname === '/searchproject') {
    sessionStorage.removeItem('viewProjectBackCheck');
    sessionStorage.removeItem('viewData');
  }
  if (location.pathname !== '/searchproject/viewproject') {
    action({
      type: 'project_others',
      payload: {
        name: 'projectBackClick',
        value: false,
      },
    });
    action({
      type: 'project_others',
      payload: {
        name: 'projectLock',
        value: {
          ProjectLockStart: '',
          SponsorShipLockDate: '',
          ProjectLockEnd: '',
          ProjectLockStatus: 'Not Started',
          ProjectLockCountry: [],
          ProjectLockRegion: '',
          ProjectLockProject: [],
        },
      },
    });
    action({
      type: 'projectLockDropdown',
      payload: {
        tabName: 'dropDownValue',
        propName: 'Region',
        propValue: [],
      },
    });
    action({
      type: 'projectLockDropdown',
      payload: {
        tabName: 'dropDownValue',
        propName: 'Country',
        propValue: [],
      },
    });
    action({
      type: 'projectLockDropdown',
      payload: {
        tabName: 'dropDownValue',
        propName: 'Project',
        propValue: [],
      },
    });
  }
  if (location.pathname !== '/administration/userGroup/operation') {
    action({
      type: 'userGroupDataAction',
      payload: {
        name: 'userGroupDetails',
        value: {},
      },
    });
    action({
      type: 'userGroupDataAction',
      payload: {
        name: 'userGroupMembersDetails',
        value: {},
      },
    });
    action({
      type: 'userGroupDataAction',
      payload: {
        name: 'activeTab',
        value: 0,
      },
    });
  }
  if (location.pathname !== '/searchproject') {
    if (
      location.pathname !== '/searchproject/viewproject' &&
      location.pathname !== '/searchproject/viewproject/auditHistory' &&
      location.pathname !== '/administration/userProfileView'
    ) {
      if (location.pathname !== '/administration/userProfileView') {
        if (location.pathname !== '/searchchild/viewchild') {
          sessionStorage.removeItem('viewProjectBackCheck');
          if (location.pathname !== '/searchchild/viewSendCsm') {
            sessionStorage.removeItem('viewData');
            sessionStorage.removeItem('fetchViewCSMData');
          }
        }
        sessionStorage.removeItem('searchPageResult');
        sessionStorage.removeItem('BasicProjectSearch');
        action({ type: 'Project_onClear' });
        sessionStorage.removeItem('gridProjectPageDetails');
        action({ type: 'Project_onClear' });
        // }
      }
    }
  }
  if (
    sessionStorage.getItem('location') ||
    sessionStorage.getItem('csmLocation') ||
    sessionStorage.getItem('csmViewGridLocation')
  ) {
    sessionStorage.removeItem('location');
    sessionStorage.removeItem('csmLocation');
    sessionStorage.removeItem('csmViewGridLocation');
  }

  return (
    <React.Fragment>
      <div className="App">
        {location.pathname !== '/auth' && <Header />}
        <Suspense
          fallback={
            <div id="center_loader_app">
              <ThreeDots color="#FF6B00" height={80} width={80} />
            </div>
          }
        >
          <Switch>
            <ProtectedRoute
              exact
              path="/"
              component={LandingScreen}
              identifier={{ module: 'Home', mode: 'View' }}
            />
            <Route exact path="/accessDeniedError" component={AccessDeniedPage} />
            <Route exact path="/unauthorized" component={UnauthorizedScreen} />

            <ProtectedRoute
              exact
              path="/searchchild"
              component={SearchRC}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/searchchild/sendcsm"
              component={SendCSM}
              identifier={{ module: 'Registered Child', mode: 'Send CSM' }}
            />
            <ProtectedRoute
              exact
              path="/searchchild/viewSendCsm"
              component={ViewCsmComponent}
              identifier={{ module: 'Registered Child', mode: 'Send CSM' }}
            />
            <ProtectedRoute
              exact
              path="/searchchild/viewchild"
              component={ViewChildComponent}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/create"
              component={CreateReservation}
              identifier={{ module: 'Reservation', mode: 'Create' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/create/search"
              component={ReservationSearchChild}
              identifier={{ module: 'Reservation', mode: 'Edit' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/manage"
              component={ManageReservation}
              identifier={{ module: 'Reservation', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/manage/:mode"
              component={ReservationViewEdit}
              identifier={{ module: 'Reservation', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userGroup"
              component={UserGroupMainPage}
              identifier={{ module: 'Admin User Group Management', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/usergroup/viewusergroup"
              component={ViewUserGroup}
              identifier={{ module: 'Admin User Group Management', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userGroup/operation"
              component={CreateUserGroup}
              identifier={{ module: 'Admin User Group Management', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userGroup/operation?mode=create"
              component={CreateUserGroup}
              identifier={{ module: 'Admin User Group Management', mode: 'Create' }}
            />

            <ProtectedRoute
              exact
              path="/administration/userprofile"
              component={UserProfile}
              identifier={{ module: 'Admin User Profile Management', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userrole"
              component={UserRole}
              identifier={{ module: 'Admin Role Management', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userrole/:mode"
              component={UserRoleCVE}
              identifier={{ module: 'Admin Role Management', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userProfileCreate"
              component={UserProfileCreate}
              identifier={{ module: 'Admin User Profile Management', mode: 'Create' }}
            />
            <ProtectedRoute
              exact
              path="/administration/userProfileView"
              component={ViewProfileComponent}
              identifier={{ module: 'Admin User Profile Management', mode: 'View' }}
            />

            <ProtectedRoute
              exact
              path="/searchproject"
              component={ProjectPage}
              identifier={{ module: 'Projects', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/searchproject/viewproject"
              component={ViewProjectComponent}
              identifier={{ module: 'Projects', mode: 'View' }}
            />

            <ProtectedRoute
              exact
              path="/reservation/import"
              component={ImportDataComponent}
              identifier={{ module: 'Reservation', mode: 'Create' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/viewImportHistory"
              component={ViewImportHistoryComp}
              identifier={{ module: 'Reservation', mode: 'Create' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/viewImportHistory/viewImportDetails"
              component={IDRCDSGridComponent}
              identifier={{ module: 'Reservation', mode: 'Create' }}
            />
            <ProtectedRoute
              exact
              path="/help"
              component={HelpScreenComponent}
              identifier={{ module: 'Home', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/pcrmNavigationScreen"
              component={pcrmResponseNavigation}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/pcrmNavigationFail"
              component={pcrmNavigationFailScreen}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />

            <ProtectedRoute
              exact
              path="/administration/FundRaisingOfficeSetting"
              component={ViewFundRaisingOfficeSetting}
              identifier={{ module: 'Admin Office Setting', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/reservation/manage/operation/reservationaudit"
              component={ReservationAuditLog}
              identifier={{ module: 'Reservation', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/searchchild/viewchild/viewchildaudit"
              component={RegisterChildAuditLog}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />

            <ProtectedRoute
              exact
              path="/searchchild/viewchild/viewCSMHistory"
              component={CSMAuditHistory}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />

            <ProtectedRoute
              exact
              path="/searchchild/viewchild/viewActionCodeHistory"
              component={ViewActionCodeHistory}
              identifier={{ module: 'Registered Child', mode: 'View' }}
            />
            <ProtectedRoute
              exact
              path="/searchproject/viewproject/auditHistory"
              component={ViewProjectAuditLog}
              identifier={{ module: 'Projects', mode: 'View' }}
            />
            <Route component={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
      </div>
    </React.Fragment>
  );
};
export default AppRouting;
