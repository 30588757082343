import React, { lazy, Suspense, memo, useState, useEffect, useRef, useCallback } from 'react';
import ColHeader from '../utils/UAAggridHelper';
import UserAccessForm from '../../../Common/UserAccessForm/UserAccessForm';
import { Divider } from 'primereact/divider';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import '../css/UserAccess.css';
import WvLanguage from '../../../Common/Multilingual/WvLanguage';
import ErrorBoundary from '../../../Common/ErrorBounaary/errorBoundary';
import { UserAccessAPI } from '../utils/service';
import { BlockUI } from 'primereact/blockui';
import { ThreeDots } from 'react-loader-spinner';
import { Messages } from 'primereact/messages';
const SummaryGrid = lazy(() => import('../../../Common/Aggrid/SummaryGrid'));
function UserAccess() {
  const USState = useSelector((state) => state.userAccessReducer);
  const [rowData, setRowData] = useState([]);
  const [loader, setLoader] = useState(false);
  const { userAccessColumns, dropDownData } = ColHeader();
  const dispatch = useDispatch();
  const history = useHistory();
  const httpErrorRef = useRef();
  const { t } = WvLanguage();
  const [deselect, setDeSelect] = useState(false);
  const allHandler = (value, name) => {
    if (name === 'showGrid') {
      apiCall();
    }
    dispatch({ type: 'UserAccess_Onchange', payload: { name, value } });
  };
  const gridCheckboxHandler = (rows) => {
    if (JSON.stringify(USState['selectedRows']) !== JSON.stringify(rows)) {
      dispatch({ type: 'UserAccess_Onchange', payload: { name: 'selectedRows', value: rows } });
    }
  };
  const ResetHandler = (flag) => {
    setDeSelect(flag);
  };
  const apiCall = useCallback(() => {
    setLoader(true);
    UserAccessAPI.getSearchResult()
      .then((rows) => {
        setRowData(rows.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        window.scrollTo(0, 0);
        httpErrorRef.current.clear();
        httpErrorRef.current.show({
          severity: 'error',
          summary: err.message,
          life: 5000,
          closable: false,
        });
      });
  }, []);
  useEffect(() => {
    if (USState.showGrid) {
      apiCall();
    }
  }, []);
  const AssignHandler = () => {
    sessionStorage.removeItem('ses_assign');
    history.push({
      pathname: '/administration/useraccess/assignrole',
      state: USState.selectedRows,
    });
  };
  return (
    <BlockUI
      blocked={loader}
      fullScreen
      template={<ThreeDots color="#FF6B00" height={80} width={80} />}
    >
      <div className="main-content-align us-mobile-div">
        <p className="p-text-left navbar-brand" style={{ fontSize: '1.4rem' }}>
          {t('user_access_management')}
        </p>
        <Divider />
        <div className="wv-bordered-div  user-access-content">
          <Messages ref={httpErrorRef} className="success-msg-div" />
          <UserAccessForm
            data={dropDownData}
            id="user_access"
            onChangeHandler={(e, name) => allHandler(e, name)}
            reducerName="userAccessReducer"
            clearHandler={() => dispatch({ type: 'UserAccess_OnClear' })}
          />
          {rowData.length !== 0 && (
            <>
              <Suspense
                fallback={
                  <div id="center_loader">
                    <ThreeDots color="#FF6B00" height={80} width={80} />
                  </div>
                }
              >
                <ErrorBoundary>
                  <Divider />
                  <SummaryGrid
                    id="User_Access"
                    gridColumnHeaders={userAccessColumns}
                    isMultiselect={true}
                    checkboxcallback={(rows) => gridCheckboxHandler(rows)}
                    fieldName="athlete"
                    stateData={USState.selectedRows}
                    isDeSelect={deselect}
                    deSelectHandler={(flag) => ResetHandler(flag)}
                    gridData={rowData}
                  />
                </ErrorBoundary>
                <div className="grid-footer-btn">
                  <Button
                    label={t('reset_button')}
                    className="p-button-outlined p-button-secondary p-mt-2 p-mb-2 wv-cancel-btn"
                    disabled={USState.selectedRows.length === 0 ? true : false}
                    onClick={() => ResetHandler(true)}
                  />
                  <Button
                    label={t('assign_button')}
                    className="p-button-warning p-mt-2 p-mb-2"
                    disabled={USState.selectedRows.length === 0 ? true : false}
                    onClick={() => AssignHandler()}
                  />
                </div>
              </Suspense>
            </>
          )}
        </div>
      </div>
    </BlockUI>
  );
}
export default memo(UserAccess);
