import React, { useState, useEffect, useRef } from 'react';
import '../CSS/LandingPage.css';
import WvLanguage from '../../../Common/Multilingual/WvLanguage';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
const defaultCss = {
  transition: 'transform 100ms ease-in-out',
  transform: 'scale(0.2)',
};
const transitionStyle = {
  entering: { transform: 'scale(0.8)' },
  entered: { transform: 'scale(1)' },
};
function LandingScreen(props) {
  const [show, setShow] = useState(false);
  const refDiv = useRef(null);
  useEffect(() => {
    setTimeout(() => setShow(true), 0);
  }, []);
  const { t } = WvLanguage();
  return (
    <>
      <div className={`land-container ${props.fullScreen && 'fullscreen'}`}>
        <div className="land-image-container"></div>

        <div ref={refDiv} className="land-bottom-container">
          <p className="pr-heading">{t('prcds')}</p>
          <p>{t('prcds_description')}</p>
        </div>
      </div>
    </>
  );
}
LandingScreen.defaultProps = {
  fullScreen: false,
};
LandingScreen.propTypes = {
  fullScreen: PropTypes.bool,
};
export default LandingScreen;
