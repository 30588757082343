const initialState = {
  res_id: '',
  reservation: {
    Reservation_Category_Cd: '',
    Reservation_Category_Type_Cd: '',
    Reservation_Name: '',
    Event_ID: '',
    Sponsor_Type_Cd: '',
    Expiration_Dt: '',
    Reservation_Participants_Target_Number: '',
    Reservation_Children_Target_Number: '',
    IsPerpetualReservation: 0,
  },
  BasicSearch: {
    ChildCountry: '',
    Project: [],
    AgeRange: {
      Operator: '',
      From: '',
      To: '',
    },
    Gender: [],
    BirthDate: {
      From: '',
      To: '',
    },
    PhotoAvailable: '',
    VideoAvailable: '',
    CountryGroup: [],
    Community: [],
  },
  AdvancedSearch: {},
  AdvancedSearchOptions: {},
  resButton: {
    isSaveDisabled: true,
    isNextDisabled: true,
    SearchChildDisable: true,
    manageSearchDisable: true,
    isSearchResetEnabled: false,
  },
  showGrid: false,
  resSelectedRows: [],
  showMsg: false,
  isRequired: false,
  manageBasicSearch: {
    ReservationID: [],
    ReservationName: [],
    ReservationCategory: [],
    ReservationCategoryType: [],
    ReservationStatus: [],
    ExpirationDate: '',
    EventID: '',
    TotalReserved: '',
    CreatedBy: [],
    CreatedDate: '',
    ChildCountry: [],
    Project: [],
    IsPerpetualReservation: '',
  },
  manageEdit: {
    ReservationID: [],
    ReservationName: '',
    ReservationCategory: '',
    ReservationCategoryType: '',
    ReservationStatus: '',
    ExpirationDate: '',
    EventID: '',
    TotalReserved: '',
    CreatedBy: '',
    CreatedDate: '',
    ChildCountry: [],
    Project: [],
    Modified_By: '',
    Modified_Dt: '',
    Total_Reserved: 0,
    Male_Reserved_Count: 0,
    Female_Reserved_Count: 0,
    Total_Sponsored: 0,
    Male_Sponsored_Count: 0,
    Female_Sponsored_Count: 0,
    Total_Available: 0,
    Total_Male_Count: 0,
    Total_Female_Count: 0,
    Sponsor_Type: '',
    IsPerpetualReservation: '',
  },
  showManageTable: '',
  tableCheckedRows: {},
  checkedRadio: '',
  isUpdated: false,
  showTransferTable: false,
  transferSelectedRow: [],
  isChildDetailAvailable: 0,
  transferTrigger: '',
  dropDown: {
    Reservation_Category_Cd: [],
    Reservation_Category_Type_Cd: [],
    Sponsor_Type_Cd: [],
    ChildCountry: [],
    Project: [],
    Gender: [],
    CountryGroup: [],
    Community: [],
    Operator: [],
  },
  manageDropDown: {
    ReservationID: [],
    ReservationName: [],
    ReservationCategory: [],
    ReservationCategoryType: [],
    ReservationCategoryTypeAll: [],
    ReservationStatus: [],
    Project: [],
    ChildCountry: [],
    CreatedBy: [],
    SponsorType: [],
    IsPerpetualReservation: [],
  },
  editActionButton: true,
  resetActionButton: true,
  confirmationChildren: [],
  searchResultCreateReservation: [],
  totalReservedCount: 0,
  isTransferDone: false,
  targetDataLoaded: true,
  isPRCDS: true,
};
const chnArray = [3];
const nonFdroAds = [
  'Child ID',
  'Has Disabilities',
  'Play Activity',
  'Education Level',
  'Grade in School',
  'Language',
  'People Group',
  'Favorite Subject',
  'Chores',
  'Field Office Last Updated Date',
  'Living With',
  'Child Greeting Video',
];
const enablingBtns = (stateData, objname, allData) => {
  if (objname === 'reservation') {
    let stateDateBtns = { ...stateData };
    delete stateDateBtns['Sponsor_Type_Cd'];
    if (Boolean(stateDateBtns['IsPerpetualReservation'])) {
      delete stateDateBtns['Expiration_Dt'];
    }
    delete stateDateBtns['IsPerpetualReservation'];
    if (chnArray.includes(stateDateBtns.Reservation_Category_Cd)) {
      delete stateDateBtns['Reservation_Participants_Target_Number'];
      delete stateDateBtns['Event_ID'];
    }

    var filteredValues = Object.values(stateDateBtns).filter((el) => Boolean(el));
    return {
      ...allData['resButton'],
      isSaveDisabled: filteredValues.length !== 0 ? false : true,
      isNextDisabled: filteredValues.length === Object.values(stateDateBtns).length ? false : true,
    };
  } else if (objname === 'BasicSearch' || objname === 'AdvancedSearch') {
    let flag = false;
    stateData = { ...allData.BasicSearch, ...allData.AdvancedSearch };
    for (const property in stateData) {
      if (
        !Array.isArray(stateData[property]) &&
        typeof stateData[property] !== 'boolean' &&
        typeof stateData[property] !== 'number'
      ) {
        flag = Object.values(stateData[property]).filter((el) => Boolean(el)).length;
        if (flag !== 0) {
          break;
        }
      } else {
        if (typeof stateData[property] === 'boolean') {
          flag = stateData[property] ? 1 : 0;
        } else if (typeof stateData[property] === 'number') {
          flag = stateData[property].toString().length;
        } else {
          flag = stateData[property].toString().length;
        }
        if (flag !== 0) {
          break;
        }
      }
    }
    return {
      ...allData['resButton'],
      SearchChildDisable: !Boolean(flag),
    };
  } else if (objname === 'manageBasicSearch') {
    var stateObj = { ...stateData };
    var filterValuesOthers = Object.values(stateObj).filter((el) => Boolean(el.toString().length));
    let manageSearchDisable =
      objname === 'manageBasicSearch'
        ? filterValuesOthers.length !== 0
          ? false
          : true
        : allData['resButton']['manageSearchDisable'];
    return {
      ...allData['resButton'],
      manageSearchDisable,
    };
  } else {
    return {
      ...allData['resButton'],
    };
  }
};
const clearData = (objName) => {
  var result = { ...initialState[objName] };
  for (let property in result) {
    if (property === 'ReservationCategory' || property === 'ReservationCategoryType') {
      result[property] = [];
    } else {
      result[property] = typeof result[property] === 'object' ? [] : '';
    }
  }
  return result;
};
const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'Res_OnChange':
      let finalObj;
      let { objName, name, value, propParent } = action.payload;
      objName = objName || 'AdvancedSearch';
      finalObj = state[objName];
      let dropDown = state[objName === 'manageBasicSearch' ? 'manageDropDown' : 'dropDown'];
      let basicSearch = state['BasicSearch'];
      if (objName === 'reservation') {
        finalObj[name] = value;
        if (name === 'Reservation_Category_Cd') {
          finalObj['Reservation_Category_Type_Cd'] = '';
          finalObj['IsPerpetualReservation'] = 0;
        }
      } else {
        if (propParent) {
          finalObj[propParent][name] = value;
          if (name === 'From' || (name === 'Operator' && value.length <= 2)) {
            finalObj[propParent]['To'] = '';
          }
          if (name === 'Operator' && value.length === 0) {
            finalObj[propParent]['From'] = '';
          }
        } else {
          if (name === 'Reservation_Category_Cd') {
            finalObj['Reservation_Category_Type_Cd'] = '';
          }
          if (name === 'ReservationCategory') {
            finalObj['ReservationCategoryType'] = objName === 'manageBasicSearch' ? [] : '';
            let codeValue = state.manageDropDown['ReservationCategory'].find(
              (e) => e.code === value
            );
            finalObj['CategoryCode'] = codeValue ? codeValue.name : '';
          } else if (name === 'ReservationCategoryType') {
            let codeValue = state.manageDropDown['ReservationCategoryType'].find(
              (e) => e.code === value
            );
            finalObj['CategoryTypeCode'] = codeValue ? codeValue.name : '';
          }
          finalObj[name] = value;
          if (name === 'ChildCountry') {
            finalObj['Project'] = [];
            finalObj['Community'] = [];
            dropDown['Project'] = [];
            dropDown['Community'] = [];
          }
          if (name === 'Project') {
            finalObj['Community'] = [];
            dropDown['Community'] = [];
          }
          if (objName === 'AdvancedSearch') {
            if (!nonFdroAds.includes(name)) {
              basicSearch['Project'] = [];
              basicSearch['Community'] = [];
              dropDown['Project'] = [];
              dropDown['Community'] = [];
            }
          }
        }
      }
      return {
        ...state,
        BasicSearch: basicSearch,
        [objName]: finalObj,
        [objName === 'manageBasicSearch' ? 'manageDropDown' : 'dropDown']: dropDown,
        resButton: enablingBtns(finalObj, objName, state),
      };
    case 'Res_OthersChange':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'Search_Other_Reservation':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'Add_Reservation':
      let constructNewOne = action.payload.makeCopy
        ? { ...state['AdvancedSearch'], ...action.payload.adsObj }
        : action.payload.adsObj;
      return {
        ...state,

        AdvancedSearch: { ...constructNewOne },
      };
    case 'Res_OnReset':
      if (action.payload.objName === 'manageBasicSearch') {
        return {
          ...state,
          [action.payload.objName]: clearData(action.payload.objName),
          resButton: {
            ...state['resButton'],
            manageSearchDisable: true,
          },
        };
      } else {
        return {
          ...state,
          [action.payload.objName]: clearData(action.payload.objName),
          res_id: '',
          showMsg: false,
          resButton: {
            ...state['resButton'],
            isSaveDisabled: true,
            isNextDisabled: true,
          },
        };
      }
    case 'Res_Search_OnClear':
      return {
        ...state,
        BasicSearch: {
          ChildCountry: action.payload.partial ? state.BasicSearch['ChildCountry'] : [],
          Project: action.payload.partial ? state.BasicSearch['Project'] : [],
          AgeRange: {
            Operator: '',
            From: '',
            To: '',
          },
          Gender: state['dropDown']['Gender'],
          BirthDate: {
            From: '',
            To: '',
          },
          PhotoAvailable: false,
          VideoAvailable: false,
          CountryGroup: [],
          Community: [],
        },
        AdvancedSearch: {},
        AdvancedSearchOptions: {},
        dropDown: {
          ...state.dropDown,
          ChildCountry: action.payload.partial ? state.dropDown['ChildCountry'] : [],
          Project: action.payload.partial ? state.dropDown['Project'] : [],
        },
        resButton: {
          ...state['resButton'],
          SearchChildDisable: action.payload.partial ? false : true,
        },
        showGrid: false,
      };
    case 'Res_bulk_update':
      return {
        ...state,
        [action.payload.objName]: {
          ...state[action.payload.objName],
          ...action.payload.value,
        },
      };
    case 'Reservation_Advanced_search_option':
      let allOptions = state.AdvancedSearchOptions;
      allOptions[action.payload.name] = action.payload.value;
      return {
        ...state,
        AdvancedSearchOptions: allOptions,
      };
    case 'Res_selected_table':
      let currentObj = { ...state.tableCheckedRows };
      currentObj[action.payload.name] = action.payload.value;
      return {
        ...state,
        tableCheckedRows: currentObj,
      };
    case 'Res_master_reset':
      return {
        ...state,
        manageEdit: {
          ReservationID: [],
          ReservationName: '',
          ReservationCategory: '',
          ReservationCategoryType: '',
          ReservationStatus: '',
          ExpirationDate: '',
          EventID: '',
          TotalReserved: '',
          CreatedBy: '',
          CreatedDate: '',
          ChildCountry: [],
          Project: [],
          Modified_By: '',
          Modified_Dt: '',
          Total_Reserved: 0,
          Male_Reserved_Count: 0,
          Female_Reserved_Count: 0,
          Total_Sponsored: 0,
          Male_Sponsored_Count: 0,
          Female_Sponsored_Count: 0,
          Total_Available: 0,
          Total_Male_Count: 0,
          Total_Female_Count: 0,
          Sponsor_Type: '',
        },
      };
    default:
      return state;
  }
};
export default reservationReducer;
