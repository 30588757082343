const initialState = {
  ChildData: false,
  SupportOfficeChildName: '',
  SupportOfficeComments: '',
  SupportOfficeFullChildName: '',
  SupportOfficeFullComments: '',
  Education: [],
  Health: [],
  Nutrition: [],
  Family: [],
  Participation: [],
  Correspondence: [],
  Media: [],
  Presence: [],
  Sponsor: [],
  defaultValues: {},
  showModal: false,
};
const viewChildReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'onInputChange':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'clearVCData':
      return {
        ...state,
        SupportOfficeChildName: '',
        SupportOfficeComments: '',
      };
    case 'viewChildOthersUpdate':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
export default viewChildReducer;
