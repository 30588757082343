import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { stringify } from 'query-string';
import * as workerTimers from 'worker-timers';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Button } from 'primereact/button';
import WvLanguage from '../component/Common/Multilingual/WvLanguage';
import './IdleTimeComponent.css';
import { BlockUI } from 'primereact/blockui';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import OidcService from './utils/service';
const IdleTimeComponent = () => {
  const LoginReduxData = useSelector((state) => state.LoginReducer);
  const action = useDispatch();
  const { t } = WvLanguage();
  const location = useLocation();
  const [DisplayModal, setDisplayModal] = useState(false);
  const [CurrentState, SetCurrentState] = useState('Active');
  const [promptBeforeIdle, setPromptBeforeIdle] = useState(60000);
  const [TimeOutDuration, setTimeOutDuration] = useState(600000);
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(TimeOutDuration);
  const getParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const onIdle = () => {
    SetCurrentState('Idle');
    setDisplayModal(true);
    message('logout');
  };

  const onActive = () => {
    SetCurrentState('Active');
    message('Active');
    setDisplayModal(false);
  };

  const onPrompt = () => {
    SetCurrentState('Prompted');
    setDisplayModal(true);
    message('Prompted');
  };

  const messageHandler = (msg, emitOnSelf = true) => {
    if (msg === 'logout') {
      LogoutHandler();
    } else if (msg !== 'Prompted') {
      setDisplayModal(false);
    }
  };
  const { getRemainingTime, activate, message, getLastActiveTime } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: TimeOutDuration,
    promptBeforeIdle,
    stopOnIdle: true,
    crossTab: true,
    name: 'PRCDS',
    onMessage: messageHandler,
  });
  const StayHandler = () => {
    setDisplayModal(false);
    activate();
    if (localStorage.getItem('oneLoginDetails') !== null) {
      isTokenExpired();
    }
    message('refresh');
  };
  const LogoutHandler = () => {
    localStorage.clear();
    window.location.assign('https://wvi.onelogin.com/oidc/2/logout');
  };
  useEffect(() => {
    const interval = workerTimers.setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      let currTime = moment();
      let lastRefreshTime = moment(LoginReduxData.lastRefeshAt);
      let diff = currTime.diff(lastRefreshTime, 'minutes');
      if (diff > 30) {
        isTokenExpired(); //token check in every 30 minutes
      }
    }, 1000);

    return () => {
      workerTimers.clearInterval(interval);
    };
  });
  useEffect(() => {
    let LoginDuration = LoginReduxData.userActiveTime || '10';
    LoginDuration = +LoginDuration * 60000;
    setTimeOutDuration(LoginDuration);
    if (localStorage.getItem('oneLoginDetails') !== null) {
      isTokenExpired();
    }
  }, [LoginReduxData.userActiveTime]);
  useEffect(() => {
    if (CurrentState === 'Idle') {
      LogoutHandler();
    }
  }, [CurrentState]);
  // useEffect(() => {
  //   if (localStorage.getItem('oneLoginDetails') !== null) {
  //     isTokenExpired();
  //   }
  // }, [location]);
  const PKCEAuthCodeSecondStep = () => {
    let oidcURL = `${process.env.REACT_APP_IDP_URL}/token`;
    let params = stringify({
      grant_type: 'refresh_token',
      refresh_token: JSON.parse(localStorage.getItem('oneLoginDetails')).refresh_token,
      client_id: process.env.REACT_APP_CLIENT_ID,
    });
    return axios
      .post(oidcURL, params, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      .then((res) => {
        let data = JSON.stringify(res.data);
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('oneLoginDetails', data);
        localStorage.setItem('profile', JSON.stringify(jwtDecode(res.data.id_token)));
        action({
          type: 'Common_Others',
          payload: {
            name: 'lastRefeshAt',
            value: new Date().getTime(),
          },
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isTokenExpired = (stay = false) => {
    if (getParams.get('type') === 'nonav') {
      let storageToken = localStorage.getItem('pcrm_token');
      storageToken = storageToken ? JSON.parse(storageToken) : {};
      storageToken = storageToken.RefreshToken;
      let rtoken = getParams.get('rtoken') || storageToken;
      OidcService.refreshPCRMToken(rtoken)
        .then((res) => {
          let data = JSON.stringify(res.data);
          localStorage.setItem('pcrm_token', data);
          action({
            type: 'Common_Others',
            payload: {
              name: 'lastRefeshAt',
              value: new Date().getTime(),
            },
          });
        })
        .catch((err) => {
          localStorage.clear();
          window.location.reload();
        });
    } else {
      const tokenDetails = JSON.parse(localStorage.getItem('oneLoginDetails'));
      const refreshToken = tokenDetails ? tokenDetails.refresh_token : null;
      if (refreshToken) {
        PKCEAuthCodeSecondStep()
          .then((res) => {
            if (res && res.expires_in && res.expires_in !== 0 && res.expires_in != null) {
              const timeSeconds = res.expires_in;
              const expiryTimeInMilliSeconds = timeSeconds * 1000 - 120000; // res.expires_in * 1000; showing popup after last 3 min so added 120000

              if (!stay) {
                message('silentRefresh');
              }
              activate();
            }
          })
          .catch((e) => {
            localStorage.clear();
            window.location.reload();
          });
      }
    }
  };
  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';
  const footer = (
    <div>
      <Button
        className="p-button-outlined p-button-secondary wv-cancel-btn"
        label={t('no_button')}
        tooltipOptions={{ position: 'left', className: 'idle-tool-tip-class' }}
        onClick={() => {
          message('logout');
          SetCurrentState('Idle');
        }}
      />
      <Button
        className="p-button-warning"
        label={t('yes_button')}
        tooltipOptions={{ position: 'right', className: 'idle-tool-tip-class' }}
        onClick={StayHandler}
        autoFocus
      />
    </div>
  );
  return (
    <>
      <Dialog
        header={t('session_expire_alert_header')}
        visible={DisplayModal}
        style={{ width: '35vw' }}
        breakpoints={{ '1000px': '70vw', '500px': '95vw' }}
        footer={footer}
        onHide={() => setDisplayModal(false)}
        closable={false}
        className="rcds-popup"
      >
        {remaining !== 0 && (
          <label>
            <span className="p-confirm-dialog-icon pi pi-exclamation-triangle"> </span>{' '}
            <label>{t('time_remaining', { seconds: remaining })}</label>
          </label>
        )}
        {remaining === 0 && <strong>{t('expired_idle_msg')}</strong>}
      </Dialog>
    </>
  );
};
export default IdleTimeComponent;
