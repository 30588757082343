const initialStateVal = {
  userGroupMainData: [],
  userGroupDetails: {},
  userGroupMembersDetails: {},
  isUnsaveChangeExist: false,
  showUnsaveModel: false,
  activeTab: 0,
  backBtnClick: false,
  unSaveChangeTabIndex: 0,
  allRolesDropDown: [],
  selectedUser: [],
  allUserGroups: [],
  duplicateGroupName: false,
};
const userGroupReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case 'userGroupDataAction':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'updateGroupData':
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case 'updateStatusOfRow':
      let original = [...state[action.payload.tabName][action.payload.propName]];
      console.log(original, '30');
      original[action.payload.propIndex]['UserRoleStatus'] = action.payload.propValue;
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: original,
        },
      };
    default:
      return state;
  }
};
export default userGroupReducer;
