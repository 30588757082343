import Wvlanguage from '../../../Common/Multilingual/WvLanguage';
function ColHeader() {
  const { t } = Wvlanguage();
  const userAccessColumns = [
    {
      colId: 'userName',
      headerName: `${t('user_number')}`,
      field: 'athlete',
      minWidth: 150,
      maxWidth: 160,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('user_number')}`,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    {
      colId: 'userName',
      headerName: `${t('user_name')}`,
      field: 'athlete',
      minWidth: 180,
      maxWidth: 200,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('user_name')}`,
    },
    {
      colId: 'region',
      headerName: `${t('region')}`,
      field: 'athlete',
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      filter: false,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('region')}`,
    },
    {
      colId: 'office',
      headerName: `${t('office')}`,
      field: 'age',
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('office')}`,
    },
    {
      colId: 'wvCentral',
      headerName: `${t('wvcentral_id')}`,
      field: 'age',
      minWidth: 180,
      maxWidth: 150,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('wvcentral_id')}`,
    },
    {
      colId: 'userMailId',
      headerName: `${t('user_mail_id')}`,
      field: 'age',
      minWidth: 220,
      maxWidth: 230,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('user_mail_id')}`,
    },
    {
      colId: 'roleMapped',
      headerName: `${t('role_mapped')}`,
      field: 'age',
      minWidth: 200,
      maxWidth: 200,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('role_mapped')}`,
    },
  ];
  const assignRoleColumns = [
    {
      colId: 'userName',
      headerName: `${t('user_number')}`,
      field: 'athlete',
      minWidth: 150,
      maxWidth: 150,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('user_number')}`,
    },
    {
      colId: 'userName',
      headerName: `${t('user_name')}`,
      field: 'athlete',
      minWidth: 150,
      maxWidth: 180,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('userName')}`,
    },
    {
      colId: 'region',
      headerName: `${t('region')}`,
      field: 'athlete',
      minWidth: 100,
      maxWidth: 120,
      sortable: true,
      filter: false,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('region')}`,
    },
    {
      colId: 'office',
      headerName: `${t('office')}`,
      field: 'age',
      minWidth: 100,
      maxWidth: 120,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('office')}`,
    },
    {
      colId: 'wvCentral',
      headerName: `${t('wvcentral_id')}`,
      field: 'age',
      minWidth: 100,
      maxWidth: 150,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('wvcentral_id')}`,
    },
    {
      colId: 'userMailId',
      headerName: `${t('user_mail_id')}`,
      field: 'age',
      minWidth: 180,
      maxWidth: 200,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('user_mail_id')}`,
    },
    {
      colId: 'roleMapped',
      headerName: `${t('role_mapped')}`,
      field: 'age',
      minWidth: 150,
      maxWidth: 200,
      sortable: true,
      filter: true,
      editable: false,
      suppressColumnsToolPanel: false,
      suppressMenu: false,
      headerTooltip: `${t('role_mapped')}`,
    },
  ];
  const roles = [
    { label: 'Partnership Admin', value: 'Partnership Admin' },
    { label: 'Local Administrator', value: 'Local Administrator' },
    { label: 'Standard User', value: 'Standard User' },
    { label: 'View Only User', value: 'View Only User' },
    { label: 'Guest - 01', value: 'Guest - 01' },
  ];
  const dropDownData = [
    {
      region: 'region 1',
      useroffice: [
        {
          name: 'Office1',
          code: 'Office1',
        },
        {
          name: 'Office2',
          code: 'Office2',
        },
        {
          name: 'Office3',
          code: 'Office3',
        },
      ],
    },
    {
      region: 'region 2',
      useroffice: [
        {
          name: 'Office21',
          code: 'Office21',
        },
        {
          name: 'Office22',
          code: 'Office22',
        },
        {
          name: 'Office23',
          code: 'Office23',
        },
      ],
    },
    {
      region: 'region 3',
      useroffice: [
        {
          name: 'Office30',
          code: 'Office30',
        },
        {
          name: 'Office31',
          code: 'Office31',
        },
        {
          name: 'Office32',
          code: 'Office32',
        },
      ],
    },
  ];
  return { userAccessColumns, roles, dropDownData, assignRoleColumns };
}
export default ColHeader;
