import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import rootReducer from './ReduxIndex';
const store = createStore(
  rootReducer,
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? applyMiddleware(logger, thunk)
    : applyMiddleware(thunk)
);
export default store;
